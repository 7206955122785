import styled, { css } from 'styled-components';
import { resetButton } from '../../../styles';
import { MOBILE_QUERY } from '../../../styles/breakpoints';
import { th } from '../../../styles/theming/helpers';

interface PromoCodeContainerProps {
  readonly fontWeight?: string;
  readonly fontName?: string;
  showMobileVersion?: boolean;
}

const PromoCodeContainerMobile = `
  font-size: 12px !important;
`;

export const PromoCodeContainer = styled.span.withConfig({
  shouldForwardProp(prop) {
    return !['fontWeight', 'fontName'].includes(prop);
  },
})<PromoCodeContainerProps>`
  font-weight: ${({ fontWeight }) => (fontWeight ? `${fontWeight}` : `Normal`)};
  font-family: ${({ fontName }) =>
    fontName ? `'${fontName}', 'Montserrat', sans-serif` : `inherit`};

  ${MOBILE_QUERY} {
    ${PromoCodeContainerMobile}
  }
`;

interface PromoCodeTextProps {
  readonly fontSize?: number;
}

const PromoCodeTextMobile = `
  font-size: 14px !important;
`;

export const PromoCodeText = styled.span.withConfig({
  shouldForwardProp(prop) {
    return !['fontSize'].includes(prop);
  },
})<PromoCodeTextProps>`
  margin: auto 0;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : `18px`)};

  ${MOBILE_QUERY} {
    ${PromoCodeTextMobile}
  }
`;

const PromoCodeContentMobile = `
  font-size: 14px !important;
`;

export const PromoCodeContent = styled.span`
  color: ${th('primary')};
  font-weight: bold;
  padding-left: 10px;
  font-size: 21px;

  ${MOBILE_QUERY} {
    ${PromoCodeContentMobile}
  }
`;

const PromoCodeBoxMobile = `
  margin: 0;
  padding: 5px;
  height: 38px;

  &::after {
    font-size: 8px;
  }
`;

interface PromoCodeBoxTypes {
  showMobileVersion?: boolean;
}

export const PromoCodeBox = styled.button<PromoCodeBoxTypes>`
  ${resetButton};
  display: flex;
  justify-content: center;
  position: relative;
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  padding: 14px 15px;
  font-family: 'Montserrat', sans-serif;
  text-shadow: rgba(0, 0, 0, 0.5) 2px 2px 1px;
  cursor: pointer;
  min-width: 100px;

  ${MOBILE_QUERY} {
    ${PromoCodeBoxMobile}
  }

  ::after {
    position: absolute;
    bottom: 0;
    right: 15px;
    color: #efefef;
    font-size: 10px;
    padding: 1px;
    content: 'Copy to clipboard';
    transition: all 0.3s cubic-bezier(0, 0, 0, 0.56);
  }

  ${({ showMobileVersion }) =>
    showMobileVersion
      ? css`
          ${PromoCodeBoxMobile}

          ${PromoCodeContent} {
            ${PromoCodeContentMobile}
          }

          ${PromoCodeText} {
            ${PromoCodeTextMobile}
          }

          ${PromoCodeContainer} {
            ${PromoCodeContainerMobile}
          }
        `
      : null}
`;
