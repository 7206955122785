import React, { useReducer, useCallback, createContext, useContext } from 'react';
import { LocalStorage } from '../../utils/storage';

const OPTIONS_STORAGE_KEY = 'video-player-captions';

export enum OptionsPanel {
  Language,
  Style,
  FontSize,
  FontColor,
}

export interface CaptionOptions {
  fontSize: string;
  fontColor: string;
}

enum CaptionOptionsActionType {
  SetFontColor,
  SetFontSize,
}

type CaptionOptionsAction =
  | { type: CaptionOptionsActionType.SetFontColor; value: string }
  | { type: CaptionOptionsActionType.SetFontSize; value: string };

function optionsReducer(state: CaptionOptions, action: CaptionOptionsAction): CaptionOptions {
  const newState = { ...state };
  switch (action.type) {
    case CaptionOptionsActionType.SetFontColor:
      newState.fontColor = action.value;
      break;
    case CaptionOptionsActionType.SetFontSize:
      newState.fontSize = action.value;
      break;
  }
  LocalStorage.set(OPTIONS_STORAGE_KEY, newState);
  return newState;
}

const defaultOptions: CaptionOptions = {
  fontColor: '#fff',
  fontSize: '100%',
};

function getInitialOptions() {
  const storedOptions = LocalStorage.get(OPTIONS_STORAGE_KEY);
  if (typeof storedOptions === 'object') {
    return { ...defaultOptions, ...storedOptions };
  }
  return defaultOptions;
}

interface CaptionOptionsContextValue {
  captionOptions: CaptionOptions;
  setFontColor: (color: string) => void;
  setFontSize: (size: string) => void;
}

const CaptionOptionsContext = createContext<CaptionOptionsContextValue | undefined>(undefined);

export const CaptionOptionsContextProvider: React.FC = ({ children }) => {
  const [captionOptions, dispatch] = useReducer(optionsReducer, undefined, getInitialOptions);

  const setFontColor = useCallback((color: string) => {
    dispatch({ type: CaptionOptionsActionType.SetFontColor, value: color });
  }, []);

  const setFontSize = useCallback((size: string) => {
    dispatch({ type: CaptionOptionsActionType.SetFontSize, value: size });
  }, []);

  return (
    <CaptionOptionsContext.Provider
      value={{
        captionOptions,
        setFontColor,
        setFontSize,
      }}
    >
      {children}
    </CaptionOptionsContext.Provider>
  );
};

export function useCaptionOptionsContext() {
  const value = useContext(CaptionOptionsContext);
  if (!value) {
    throw new Error(
      `useCaptionOptionsContext needs to be called within CaptionOptionsContext tree`,
    );
  }
  return value;
}

export const VALID_FONT_SIZE_OPTIONS = [
  { label: '50%', value: '50%' },
  { label: '75%', value: '75%' },
  { label: '100%', value: '100%' },
  { label: '150%', value: '150%' },
  { label: '200%', value: '200%' },
  { label: '300%', value: '300%' },
  { label: '400%', value: '400%' },
];
export const VALID_FONT_COLOR_OPTIONS = [
  { label: 'Black', value: '#000' },
  { label: 'Blue', value: '#00f' },
  { label: 'Cyan', value: '#0ff' },
  { label: 'Green', value: '#0f0' },
  { label: 'Magenta', value: '#f0f' },
  { label: 'Red', value: '#f00' },
  { label: 'White', value: '#fff' },
  { label: 'Yellow', value: '#ff0' },
];

export function generateCaptionTrackId(idPrefix: string, languageCode: string) {
  return `${idPrefix}-video-track-${languageCode}`;
}
