import styled from 'styled-components';
import { th } from '../../styles/theming/helpers';
import { MOBILE_QUERY } from '../../styles';

export const Wrapper = styled.div`
  width: 100%;
  max-width: 340px;
`;

export const TabsWrapper = styled.div`
  border-bottom: 1px solid ${th('outline')};
  display: flex;
`;

interface TabProps {
  active: boolean;
}

export const Tab = styled.button<TabProps>`
  background: transparent;
  border: 0;
  /* FIXME: */
  /* it doesn't work with theme */
  /* border-bottom: ${({ active }) => (active ? `1px solid ${th('font')}` : '0')}; */
  /* color: ${({ active }) => (active ? `${th('fontDarker')}` : `${th('outline')}`)}; */
  border-bottom: ${({ active }) => (active ? `1px solid #181927` : '0')};
  color: ${({ active }) => (active ? `#151618` : `#dbdee6`)};
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  height: 100%;
  letter-spacing: -0.16px;
  margin-bottom: -1px;
  outline: 0;
  padding-bottom: 14px;

  ${MOBILE_QUERY} {
    font-size: 12px;
  }

  &:first-child {
    margin-right: 20px;
  }
`;

export const Details = styled.div`
  display: flex;
  margin-top: 16px;
`;

interface ThumbnailProps {
  image: string | null | undefined;
}

export const Thumbnail = styled.div<ThumbnailProps>`
  background-image: ${({ image }) => image && `url(${image})`};
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  flex-shrink: 0;
  height: 44px;
  margin-right: 18px;
  width: 64px;
`;

export const VideoTitle = styled.div`
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0;
  // modals background is not customizable, no need for theme here
  color: #000;
`;

export const SocialPanel = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px 0;
  max-width: 200px;
  margin: 0 auto;
`;

export const IconButton = styled.button`
  align-items: center;
  background-color: ${th('outline')};
  border: 0;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  outline: 0;
  width: 40px;
`;

export const CopyInputWrapper = styled.div`
  align-items: center;
  border-radius: 22px;
  border: 1px solid ${th('outline')};
  display: flex;
  height: 44px;
  padding: 0 14px;
`;

export const Input = styled.input`
  border: 0;
  margin-right: 20px;
  outline: 0;
  width: 100%;
`;

interface CopyButtonProps {
  tooltipVisible: boolean;
}

export const CopyButton = styled.button<CopyButtonProps>`
  background: transparent;
  border: 0;
  color: ${th('primary')};
  cursor: pointer;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 500;
  height: 100%;
  letter-spacing: -0.14px;
  outline: none;
  padding: 0%;
  position: relative;

  ::after {
    background-color: ${th('background')};
    border-radius: 5px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    content: 'Copied!';
    opacity: ${({ tooltipVisible }) => (tooltipVisible ? 1 : 0)};
    padding: 8px;
    position: absolute;
    right: -5px;
    top: 16px;
    transform: translateY(-150%);
    transition: opacity 0.2s ease-in;
  }
`;
