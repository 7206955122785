import styled, { css } from 'styled-components';
import { th, TABLET_QUERY, MOBILE_QUERY, alpha, resetButton } from '../../../styles';
import { FontWeight } from '../../../types';

export interface ActionButtonWrapperProps {
  transparent?: boolean;
  hideOnMobile?: boolean;
  buttonColor?: string;
  fontWeight?: string | FontWeight;
  fontSize?: string;
  fontFamily?: string;
  iconColor?: string;
  showMobileVersion?: boolean;
  borderColor?: string;
  borderWidth?: string;
  borderRadius?: string;
  backgroundOpacity?: string;
  fontColor?: string;
  iconValue?: string | null;
}

const ActionButtonSubtitleMobile = `
  font-size: 11px;
`;

export const ActionButtonSubtitle = styled.span`
  display: block;
  font-size: 12px;
  font-weight: normal;

  ${TABLET_QUERY} {
    ${ActionButtonSubtitleMobile}
  }
`;

const ActionButtonWrapperTablet = `
  font-size: 14px;

`;

const ActionButtonWrapperMobile = `
  font-size: 12px !important;
  padding: 5px;

  :not(:last-child) {
    margin-right: 0;
  }
`;

export const ActionButtonWrapper = styled.button<ActionButtonWrapperProps>`
  background: transparent;
  border: none;
  padding: ${({ iconValue }) => (iconValue ? '8px 20px 8px 8px' : '14px 20px')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-style: solid;
  border-color: ${({ borderColor }) => borderColor || 'transparent'};
  border-width: ${({ borderWidth }) => borderWidth || '0'};
  border-radius: ${({ borderRadius }) => borderRadius || '0'};
  background-color: ${({ buttonColor, backgroundOpacity }) =>
    alpha(buttonColor || '#000000', Number(backgroundOpacity) || 1)};
  color: ${({ fontColor }) => fontColor || th('primary')};
  font-size: ${({ fontSize }) => fontSize || '14px'};

  span {
    font-family: ${({ fontFamily }) => fontFamily || 'sans-serif'};
  }

  svg {
    margin-right: 0 !important;
    stroke: ${({ iconColor }) => iconColor || th('primary')};
    width: 16px;
    height: 16px;
  }

  ${TABLET_QUERY} {
    ${ActionButtonWrapperTablet}
  }

  ${MOBILE_QUERY} {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex;')};
    ${ActionButtonWrapperMobile}
  }

  ${({ showMobileVersion }) =>
    showMobileVersion
      ? css`
          svg {
            max-width: 32px;
          }

          ${ActionButtonWrapperTablet}
          ${ActionButtonWrapperMobile}
        `
      : null}
`;

export const IconsWrapper = styled.div<{ color: string }>`
  position: relative;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  color: ${({ color }) => color};
  svg {
    width: 30px;
    height: 32px;
  }
`;

export const IconWrapper = styled.div<{ color: string }>`
  position: absolute;
  display: flex;
  color: ${({ color }) => color};
  svg {
    width: 16px;
    height: 16px;
  }
`;
