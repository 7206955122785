import styled from 'styled-components';
import { MOBILE_QUERY, TABLET_QUERY, th } from '../../styles';
import { PAUSE_SCREEN_Z_INDEX } from '../../styles';

export const Container = styled.div`
  background-color: ${th('navy', 0.82)};
  padding: 24px 24px 60px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: ${PAUSE_SCREEN_Z_INDEX};
  box-sizing: border-box;

  ${TABLET_QUERY} {
    padding: 45px;
  }
`;

export const ButtonsAndSliderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`;

export const Title = styled.span`
  color: ${th('bg')};
  display: block;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.21px;
  line-height: 1.33;

  ${TABLET_QUERY} {
    font-size: 18px;
  }

  ${MOBILE_QUERY} {
    display: none;
  }
`;

export const ActionsWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;

  ${MOBILE_QUERY} {
    align-items: center;
    display: block;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    margin: 0;
  }
`;

export const SliderWrapper = styled.div`
  margin: 0 auto;
  padding: 5% 35px 0;
  width: 100%;

  ${TABLET_QUERY} {
    padding: 5% 0 0;
  }

  ${MOBILE_QUERY} {
    display: none;
  }
`;

export const NonSliderContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ArrowBase = styled.button`
  background: ${th('primary')};
  border-radius: 8px;
  border: none;
  color: ${th('invertFont')};
  display: none;
  height: 34px;
  position: absolute;
  top: 30px;
  width: 34px;
  z-index: 5;

  svg {
    bottom: 0;
    height: 12px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 7px;
  }

  &:before {
    display: none;
  }
`;

export const ArrowLeft = styled(ArrowBase)`
  transform: rotate(-180deg);
  left: -60px;

  ${TABLET_QUERY} {
    left: 0;
  }
`;

export const ArrowRight = styled(ArrowBase)`
  right: -50px;

  ${TABLET_QUERY} {
    right: 0;
  }
`;

export const ScreenHeader = styled.div`
  display: flex;
  flex: row nowrap;
  justify-content: space-between;
`;

export const ReplayButton = styled.button`
  display: flex;
  flex: row nowrap;
  color: ${th('bg')};
  cursor: pointer;
  transition: opacity 0.15s ease-in;
  border: 0;
  background: transparent;

  span {
    padding-left: 14px;
    padding-right: 14px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${TABLET_QUERY} {
    position: absolute;
    top: 16px;
    right: 6px;
  }
`;
