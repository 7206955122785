export enum ObjectAnnotationCategory {
  Person = 'PERSON',
  Product = 'PRODUCT',
  Location = 'LOCATION',
  Other = 'OTHER',
}

export interface Indicator {
  x: number;
  y: number;
}

export interface Block {
  id: string;
  timestamp: number;
  indicator: Indicator;
}

export interface Segment {
  startTimestamp: number;
  endTimestamp: number;
  blocks: Block[];
}

export interface ObjectAnnotation {
  id: string;
  title: string;
  url?: string;
  imageUrl?: string;
  category: ObjectAnnotationCategory;
  segments: Segment[];
  buttonCta?: string;
  promote: boolean;
}

export interface PositionObjectAnnotation extends ObjectAnnotation {
  position: Indicator;
}

export type ObjectSliderItem = Pick<
  ObjectAnnotation,
  'title' | 'url' | 'imageUrl' | 'id' | 'buttonCta'
>;
