import styled, {
  keyframes,
  css,
  Keyframes,
  FlattenSimpleInterpolation,
  StyledComponent,
} from 'styled-components';
import { th } from '../../../styles/theming';
import variants from '../../../helpers/variants';

const DOTTS_N = 3;

const variantColor = variants({
  variantName: 'color',
  variantsTable: {
    dark: {
      bg: th('primary'),
    },
    light: {
      bg: th('bg'),
    },
  },
  defaultVariant: 'light',
});

type DottChildrenType = (
  Comp: StyledComponent<'div', any, {}, never>,
  keyFrames: Keyframes[],
) => FlattenSimpleInterpolation[];

const dottChildren: DottChildrenType = (Comp, dottsAnimations) =>
  Array.from(Array(DOTTS_N).keys()).map<FlattenSimpleInterpolation>(
    (nx) => css`
      ${Comp}:nth-child(${nx + 2}) {
        left: ${8 + nx * 24}px;
        animation: ${dottsAnimations[nx + 1]} 0.6s infinite;
      }
    `,
  );

const dottsAnimations = [
  keyframes`
    0% {
    transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  `,
  keyframes`
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  `,
  keyframes`
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
  `,
  keyframes`
    0% {
    transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
  `,
];

export const Dott = styled.div`
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 80px;
  height: 80px;
  z-index: 3;

  ${Dott} {
    background: ${variantColor('bg')};
  }
  ${'' /* } expected ts-styled-plugin(9999) */}
  ${`${Dott}:nth-child (1)`} {
    left: 8px;
    animation: ${dottsAnimations[0]} 0.6s infinite;
  }

  ${dottChildren(Dott, dottsAnimations)}
`;
