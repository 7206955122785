type AnalyticsEventsHandler<T> = (type: T, info?: string) => void;

export enum PlayerControlsEvents {
  play = 'play',
  mute = 'mute',
  progressBar = 'progressBar',
  fullscreen = 'fullscreen',
  playbackRate = 'playbackRate',
  share = 'shareButton',

  volumeChange = 'volumeChange',
  quality = 'quality',
  captions = 'captions',
  captionsFontColor = 'captionsFontColor',
  captionsFontSize = 'captionsFontSize',
  objectAnnotations = 'objectAnnotations',

  mobileQuality = 'mobileQuality',
  mobileSettings = 'mobileSettings',
  mobileCaptions = 'mobileCaptions',
  mobileObjectAnnotations = 'mobileObjectAnnotations',
}
export enum ShareEvents {
  open = 'shareOpen',
  social = 'social',
  copy = 'copy',
}

export enum PauseScreenEvents {
  ctaClick = 'ctaClick',
  ctaClick1 = 'ctaClick1',
  ctaClick2 = 'ctaClick2',
  replay = 'replay',
  promoCode = 'promoCode',
  watchNextMoment = 'watchNextMoment',
  watchFullVideo = 'watchFullVideo',
  share = 'share',
  socialMedia = 'socialMedia',
}

export enum ViewVideoEvents {
  addView = 'addView',
}
export enum VideoCTAsEvents {
  buttonCTAClick = 'videoButtonCTAClick',
  imageCTAClick = 'videoImageCTAClick',
}

export type PlayerControlsEventsHandler = AnalyticsEventsHandler<PlayerControlsEvents>;
export type ShareEventsHandler = AnalyticsEventsHandler<ShareEvents>;
export type PauseScreenEventsHandler = AnalyticsEventsHandler<PauseScreenEvents>;
export type ViewVideoEventsHandler = AnalyticsEventsHandler<ViewVideoEvents>;
export type VideoCTAsEventsHandler = AnalyticsEventsHandler<VideoCTAsEvents>;
