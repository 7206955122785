import React from 'react';
import defaultThumbnail from '../../assets/images/object-placeholder@2x.jpg';
import { LinkableProps, LinkController, LinkItem } from '../Utils/Link';
import { GoArrow } from './../../assets/icons';

import * as Styled from './ExternalContentSlider.styles';

export interface ExternalContentSliderElementProps extends LinkableProps {
  title: string;
  imageUrl?: string;
  link?: LinkItem;
  buttonCta?: string;
}

export const ExternalContentSliderElement: React.FC<ExternalContentSliderElementProps> = ({
  title,
  imageUrl,
  linkComponent,
  link,
  buttonCta = 'See more',
}) => {
  return (
    <Styled.SlideWrapper>
      <Styled.Slide>
        <Styled.ImageWrapper>
          {link?.externalUrl ? (
            <LinkController component={linkComponent} item={link}>
              <Styled.CTA>
                {buttonCta} <GoArrow className="cta-arrow-icon" />
              </Styled.CTA>
            </LinkController>
          ) : null}
          <Styled.SlideImage src={imageUrl || defaultThumbnail} alt={title} />
        </Styled.ImageWrapper>
        <Styled.SlideTitle>{title}</Styled.SlideTitle>
      </Styled.Slide>
    </Styled.SlideWrapper>
  );
};
