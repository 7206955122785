import React from 'react';
import ReactModal from 'react-modal';

import { IconButton } from '../IconButton/IconButton';
import { Close } from '../../../assets/icons';
import * as Styled from './Modal.styles';

export interface ModalProps {
  children?: React.ReactNode;
  title?: string;
  fullWidth?: boolean;
  modalVisible: boolean;
  onClose: () => void;
  closeButtonText?: string;
  ariaLabel?: string;
  maxWidth?: string;
}

export function Modal({
  children,
  modalVisible,
  onClose,
  title,
  fullWidth,
  closeButtonText,
  ariaLabel,
  maxWidth,
}: ModalProps) {
  return (
    <ReactModal
      contentLabel={ariaLabel}
      isOpen={modalVisible}
      style={{
        overlay: {
          alignItems: 'center',
          backgroundColor: 'rgba(18,18,33,0.82)',
          display: 'flex',
          justifyContent: 'center',
          zIndex: 9999999,
        },
        content: {
          maxWidth: maxWidth ? maxWidth : '80%',
          width: fullWidth ? '100%' : 'unset',
          WebkitOverflowScrolling: 'touch',
          background: '#fff',
          borderRadius: '16px',
          outline: 'none',
          overflow: 'auto',
          position: 'relative',
          padding: '0px',
          right: '0',
          left: '0',
        },
      }}
    >
      {title ? (
        <Styled.Header>
          {title}
          <Styled.CloseButton>
            <IconButton aria-label="Close" color="#000" onClick={onClose}>
              <Close aria-hidden="true" focusable="false" />
            </IconButton>
          </Styled.CloseButton>
        </Styled.Header>
      ) : (
        <Styled.CloseButton>
          <IconButton aria-label="Close" color="#000" onClick={onClose}>
            <Close aria-hidden="true" focusable="false" />
          </IconButton>
        </Styled.CloseButton>
      )}
      <Styled.Content>{children}</Styled.Content>
      {closeButtonText && (
        <Styled.Footer>
          <Styled.CloseTextButton onClick={onClose}>{closeButtonText}</Styled.CloseTextButton>
        </Styled.Footer>
      )}
    </ReactModal>
  );
}

Modal.setAppElement = ReactModal.setAppElement;
