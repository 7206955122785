import styled from 'styled-components';
import { th } from '../../styles/theming/helpers';

import { MOBILE_QUERY } from '../../styles/breakpoints';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 18px 0;
  border-bottom: solid 1px ${th('outline')};
  font-weight: 500;
`;

export const NameLinkContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 2;

  ${MOBILE_QUERY} {
    flex-direction: column;
  }
`;

export const Name = styled.div`
  flex: 2;

  ${MOBILE_QUERY} {
    width: 100%;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  color: ${th('primary')};

  &:hover {
    opacity: 0.6;
  }

  span {
    color: ${th('greyLink')};
  }

  ${MOBILE_QUERY} {
    width: 100%;
    font-size: 18px;
  }
`;

export const MiniImage = styled.div`
  display: flex;
  align-items: center;

  width: 64px;
  height: 44px;
  margin-right: 20px;
  flex-shrink: 0;
  overflow: hidden;

  svg,
  img {
    display: block;
    margin: auto;
    max-width: 100%;
    max-height: 100%;
    border-radius: ${th('imageBorderRadius')};
  }
`;

export const IconWrapper = styled.div`
  .icon {
    margin-left: 3px;
    color: ${th('greyLink')};
  }
`;
