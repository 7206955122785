export const displayTimeUnit = (num: number) => (num < 10 ? `0${num}` : `${num}`);

/**
 * Convert seconds to displayable format hh:mm:ss
 */
export const secondsToFormat = (seconds: number) => {
  const secondsRest = Math.floor(seconds % 60);
  const minutes = Math.floor(seconds / 60);
  const minutesRest = minutes % 60;
  const hours = Math.floor(minutes / 60);

  const hoursDisplay = hours ? `${hours}:` : '';
  const minutesDisplay = `${hours > 0 ? displayTimeUnit(minutesRest) : minutes}:`;
  const secondsDisplay = displayTimeUnit(secondsRest);

  return `${hoursDisplay}${minutesDisplay}${secondsDisplay}`;
};

export const fractionToPercent = (fraction: number) => fraction * 100 + '%';
