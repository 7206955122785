export enum VideoMonetizationAdDensity {
  AUTO = 'AUTO',
  EVERY_1_MIN = 'EVERY_1_MIN',
  EVERY_2_MIN = 'EVERY_2_MIN',
  EVERY_3_MIN = 'EVERY_3_MIN',
  EVERY_4_MIN = 'EVERY_4_MIN',
  EVERY_5_MIN = 'EVERY_5_MIN',
  EVERY_6_MIN = 'EVERY_6_MIN',
  EVERY_7_MIN = 'EVERY_7_MIN',
  EVERY_8_MIN = 'EVERY_8_MIN',
  EVERY_9_MIN = 'EVERY_9_MIN',
  EVERY_10_MIN = 'EVERY_10_MIN',
}
export enum VideoMonetizationAdLocation {
  Preroll = 'PREROLL',
  Postroll = 'POSTROLL',
  midroll = 'MIDROLL',
}
export enum VideoMonetizationAdType {
  Skippable = 'SKIPPABLE',
  NonSkippable = 'NON_SKIPPABLE',
  Both = 'BOTH',
}
export interface OrganizationMonetization {
  id: string;
  active: Boolean;
  default: Boolean;
  monetizationUrl: String;
  name: String;
}

export interface VideoMonetization {
  isMonetizationEnabled: boolean;
  orgMonetization: OrganizationMonetization;
  useDefaultMonetization: boolean;
  adLocation: VideoMonetizationAdLocation;
  adDensity: VideoMonetizationAdDensity;
  adType: VideoMonetizationAdType;
}
