import styled from 'styled-components';

import { TABLET_QUERY, LAYOUT_QUERY } from '../../styles/breakpoints';
import { resetButton } from '../../styles/mixins';
import { th } from '../../styles/theming/helpers';

interface TitleWrapperProps {
  active?: boolean;
}

export const TitleWrapper = styled.div<TitleWrapperProps>`
  ${resetButton};
  margin-top: 12px;
  width: 100%;

  opacity: ${({ active }) => (active ? '1' : '0.7')};
`;

export const Type = styled.span`
  color: ${th('fontSecondary')};
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.28px;
`;

interface TitleProps {
  active?: boolean;
  small?: boolean;
}

export const Title = styled.div<TitleProps>`
  color: ${({ active }) => (active ? th('fontDarker') : th('fontSecondary'))};
  font-family: ${th('headingFontFamily')};
  font-weight: ${th('headingFontWeight')};
  font-size: ${({ small }) => (small ? '17px' : '26px')};
  line-height: 1.33;
  margin-top: 8px;
  overflow: hidden;
  transition: all 0.2s;
  word-break: break-word;

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  &:hover {
    text-decoration: underline;
  }

  ${LAYOUT_QUERY} {
    font-size: ${({ small }) => (small ? '14px' : '22px')};
  }

  ${TABLET_QUERY} {
    color: ${th('fontDarker')};
    font-size: ${({ small }) => (small ? '14px' : '18px')};
    font-weight: bold;
  }
`;
