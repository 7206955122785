import { useEffect } from 'react';

const useGoogleFont = (fontFamily?: string, fontWeight?: number) => {
  // @ts-ignore
  useEffect(() => {
    if (fontFamily && fontWeight) {
      const link = document.createElement('link');
      link.href = `https://fonts.googleapis.com/css2?family=${fontFamily}:wght@${fontWeight}&display=swap`;
      link.rel = 'stylesheet';
      link.id = fontFamily;
      document.head.appendChild(link);

      return () => {
        document.head.removeChild(link);
      };
    }
  }, [fontFamily, fontWeight]);
};

export default useGoogleFont;
