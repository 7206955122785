import React from 'react';
import styled from 'styled-components';

import { MOBILE_QUERY } from '../../../styles/breakpoints';
import { resetButton } from '../../../styles/mixins';

export interface IconButtonProps extends React.ComponentPropsWithoutRef<'button'> {
  isSpaced?: boolean;
  color?: string;
  rotate?: number;
  mobileOrder?: string;
}

export const IconButton = styled.button<IconButtonProps>`
  ${resetButton};

  display: inline-flex;
  color: ${({ color }) => color || '#fff'};
  transition: opacity 0.1s linear;
  margin-right: ${({ isSpaced = false }) => (isSpaced ? '15px' : 0)};
  ${({ rotate }) => typeof rotate === 'number' && `transform: rotate(${rotate}deg);`};

  position: relative;

  // hack to be able to hover svg on ms Edge
  &:after {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:hover {
    opacity: 0.5;
  }

  ${MOBILE_QUERY} {
    margin-right: 0;
    order: ${({ mobileOrder }) => mobileOrder || 'auto'};
    padding: 5px;
  }
`;
