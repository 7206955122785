import { Nullable } from '../utils/types';
import { OverlaysFontFamilyOption } from './CallToActionButtonPauseScreen';

export type VideoCTATimestampCtasResponse = { id: string } & {
  button: Nullable<ButtonCTAResponse>;
  image: Nullable<ImageCTAResponse>;
  order: number;
  config: Nullable<any>;
};

export type VideoCTATimestampResponse = { id: string } & {
  name: string;
  startTimestamp: number;
  endTimestamp: number;
  ctas: VideoCTATimestampCtasResponse[];
};

export interface VideoCTATimestampsResponse {
  ctaTimestamps: ({ id: string } & VideoCTATimestampResponse)[];
}

export enum CTAItemType {
  Button = 'BUTTON',
  Image = 'IMAGE',
}

export interface CreateButtonCTAInput {
  name: string;
  url: string;
  text: {
    text: string;
    fontFamily: Nullable<OverlaysFontFamilyOption>;
    fontSize: string;
    fontColor: string;
  };
  background: {
    backgroundColor: string;
    backgroundOpacity: string;
  };
  border: {
    borderWidth: string;
    borderColor: string;
    borderRadius: string;
  };
  icon: {
    hasIcon: boolean;
    iconValue: Nullable<string>;
    iconColor: string;
  };
}

export type ButtonCTAResponse = { id: string; created: string } & CreateButtonCTAInput;

export type ImageCTAResponse = {
  id: string;
  created: string;
  name: string;
  url: string;
  imageUrl: string;
};

export interface CTATimestampCtasPosition {
  top: number;
  left: number;
  calculatedLeft: number;
  calculatedTop: number;
}

export interface CTATimestampCtas {
  id: string;
  order: number;
  type: Nullable<CTAItemType>;
  template: Nullable<{ id: string; name: string }>;
  styles: Nullable<ButtonCTAResponse | ImageCTAResponse>;
  width: Nullable<string>;
  config: {
    position: CTATimestampCtasPosition;
  };
}

export interface CTATimestamp {
  id: string;
  name: string;
  startTimestamp: number;
  endTimestamp: number;
  ranges: number[];
  ctas: CTATimestampCtas[];
}
