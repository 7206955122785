import React, { useEffect, useRef, useState } from 'react';
import { ActionButton } from '../common/ActionButton';
import * as Styled from './NewCustomizablePauseScreen.styles';
import { LinkableProps, LinkItem } from '../Utils/Link';
import {
  CallToActionButtonPauseScreen,
  ObjectSliderItem,
  PauseScreenEventsHandler,
  PauseScreenEvents,
  PromoCodeBox,
  VideoItemMoment,
  VideoItemVideo,
  SocialMediaTypes,
  socialIconsMap,
  OverlaysCTAButton,
  EndcardOrientationOption,
} from '../../types';
import { ShareItem, useShareModal } from '../ShareModal';
import { PromoCode } from './PromoCode';
import { Rotate } from '../../assets/icons';
import { noop, stopPropagation } from '../../utils/common';
import { NewActionButton } from '../common/ActionButton/NewActionButton';
import useGoogleFont from '../../hooks/useGoogleFont';
import { NewCTAButton } from '../common/ActionButton/NewCTAButton';

export interface NewCustomizablePauseScreenProps<
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
> extends LinkableProps {
  id: string;
  name: string;
  cardOrientation: EndcardOrientationOption.HORIZONTAL | EndcardOrientationOption.VERTICAL;
  overlay: {
    color: string;
    opacity: string;
  };
  background: {
    color: string;
    opacity: string;
  };
  backgroundImageSettings: {
    size: string;
    top: string;
    left: string;
    horizontal: string;
    vertical: string;
  };
  border: {
    width: string;
    color: string;
    radius: string;
  };
  videoControlButtons: {
    iconsColor: string;
    backgroundColor: string;
    backgroundOpacity: string;
  };
  headline: {
    text: string;
    font: {
      fontFamily: string;
      fontWeight: number;
      label: string;
      value: string;
    };
    fontSize: string;
    color: string;
  };
  subline: {
    text: string;
    font: {
      fontFamily: string;
      fontWeight: number;
      label: string;
      value: string;
    };
    fontSize: string;
    color: string;
  };
  backgroundImageUrl: string;
  cardSize: string;
  promoCodeButton: {
    label: string;
    text: string;
  } | null;
  watchNextButtonVisible: boolean;
  videosCount: number;
  momentsCount: number;
  created: string;
  ctaButton1?: OverlaysCTAButton;
  ctaButton2?: OverlaysCTAButton;

  // videos: VideoI[];
  // moments: MomentI[];
  title?: string;
  objects: ObjectSliderItem[];
  // video object is needed only if the base player is displaying a moment (but still is optional)
  video?: TVideo;
  moment?: TMoment;
  nextMoment?: LinkItem;
  nextMomentHandler?: () => void;
  onReplay?: () => void;
  isVisible: boolean;
  replay?: () => void;
  showShareMoment?: boolean;
  showWatchFullVideo?: boolean;
  showWatchNextMomentButton?: boolean;
  promoCode?: PromoCodeBox;
  callToActionButton?: CallToActionButtonPauseScreen;
  justifyContent?: string;
  analyticsHandler?: PauseScreenEventsHandler;
  showMobileVersion?: boolean;
  socialMediaButtons?: SocialMediaTypes[];
  customActionWatchFullVideo?: () => void;
}

const generateOpacity = (opacity: string) => {
  const opacityString = opacity && opacity.split('%')[0];
  return opacityString ? Number(opacityString) / 100 : 1;
};

export function NewCustomizablePauseScreen<
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
>({
  id,
  name,
  overlay,
  background,
  backgroundImageSettings,
  border,
  videoControlButtons,
  headline,
  subline,
  backgroundImageUrl,
  cardSize,
  watchNextButtonVisible,
  videosCount,
  momentsCount,
  created,
  promoCodeButton,
  linkComponent,
  cardOrientation,
  objects,
  video,
  moment,
  nextMoment,
  nextMomentHandler,
  title,
  onReplay,
  isVisible,
  showShareMoment = true,
  showWatchFullVideo = true,
  showWatchNextMomentButton = true,
  promoCode,
  justifyContent,
  analyticsHandler = noop,
  showMobileVersion,
  socialMediaButtons = [],
  customActionWatchFullVideo = noop,
  ctaButton1,
  ctaButton2,
}: NewCustomizablePauseScreenProps<TVideo, TMoment>) {
  const [showScrollBar, setShowScrollBar] = useState(false);
  const { showModal } = useShareModal<TVideo, TMoment>();
  const canShowShareMoment = showShareMoment;
  const canShowWatchNextMomentButton =
    (nextMoment || nextMomentHandler) && showWatchNextMomentButton;
  const scrollableElement = useRef<HTMLDivElement>(null);
  const contentToScroll = useRef<HTMLDivElement>(null);

  const handleReplay = () => {
    onReplay?.();
    analyticsHandler(PauseScreenEvents.replay);
  };

  useEffect(() => {
    if (scrollableElement.current && contentToScroll.current) {
      setShowScrollBar(
        scrollableElement.current.clientHeight > contentToScroll.current.clientHeight,
      );
    }
  }, [contentToScroll, scrollableElement]);

  const computedTitle = title || moment?.title;
  useGoogleFont(headline?.font?.fontFamily, headline?.font?.fontWeight);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useGoogleFont(subline?.font?.fontFamily, subline?.font?.fontWeight);
  useGoogleFont(ctaButton1?.fontFamily?.fontFamily, ctaButton1?.fontFamily?.fontWeight);
  useGoogleFont(ctaButton2?.fontFamily?.fontFamily, ctaButton2?.fontFamily?.fontWeight);

  return isVisible ? (
    <Styled.Container
      showMobileVersion={showMobileVersion}
      showScrollBar={showScrollBar}
      overlayBgColor={overlay?.color}
      overlayBgOpacity={`${generateOpacity(overlay?.opacity)}`}
      cardOrientation={cardOrientation}
    >
      <Styled.ActionsWrapper removeMarginBottom={true} style={{ flexDirection: 'column' }}>
        {socialMediaButtons?.length
          ? socialMediaButtons.map((social) => (
              <ActionButton
                key={social.id}
                linkComponent={linkComponent}
                title={social.label}
                link={{ externalUrl: social.accountUrl }}
                iconName={socialIconsMap[social.platformName]}
                showMobileVersion={showMobileVersion}
                showButtonLabel={false}
                transparent={true}
                iconVersion={true}
                customIconColor={videoControlButtons?.iconsColor}
                onClick={() =>
                  analyticsHandler(PauseScreenEvents.socialMedia, social?.platformName)
                }
              />
            ))
          : null}
      </Styled.ActionsWrapper>

      <Styled.ButtonsAndSliderWrapper ref={scrollableElement} showScrollBar={showScrollBar}>
        <Styled.ButtonsContainer
          ref={contentToScroll}
          cardBgColor={background?.color}
          cardBgOpacity={`${generateOpacity(background?.opacity)}`}
          overlayBorderRadius={border?.radius}
          overlayBorderColor={border?.color}
          overlayBorderWidth={border?.width}
          cardSize={cardSize}
        >
          {backgroundImageUrl && (
            <Styled.BackgroundImage
              verticalAlignment={backgroundImageSettings.vertical}
              horizontalAlignment={backgroundImageSettings.horizontal}
              imageWidth={backgroundImageSettings?.size}
              backgroundUrl={backgroundImageUrl}
            />
          )}
          <Styled.OverlayTextContainer>
            <Styled.OverlayTitle
              overlayTitleColor={headline?.color}
              overlayTitleFontSize={headline?.fontSize}
              overlayTitleFontFamily={headline?.font?.fontFamily}
            >
              {headline?.text}
            </Styled.OverlayTitle>
            <Styled.OverlaySubTitle
              overlayTitleColor={subline?.color}
              overlayTitleFontSize={subline?.fontSize}
              overlayTitleFontFamily={subline?.font?.fontFamily}
            >
              {subline?.text}
            </Styled.OverlaySubTitle>
          </Styled.OverlayTextContainer>
          <div>
            {canShowShareMoment || canShowWatchNextMomentButton || showWatchFullVideo ? (
              <Styled.ActionsWrapper justifyContent={justifyContent}></Styled.ActionsWrapper>
            ) : null}
            {promoCodeButton ? (
              <Styled.ActionsWrapper>
                {promoCodeButton && (
                  <PromoCode
                    // @ts-ignore
                    code={promoCodeButton?.label}
                    text={promoCodeButton?.text}
                    showMobileVersion={showMobileVersion}
                    onCopy={() => {
                      analyticsHandler(PauseScreenEvents.promoCode);
                    }}
                  />
                )}
              </Styled.ActionsWrapper>
            ) : null}
          </div>
          <Styled.ActionsWrapper cardOrientation={cardOrientation}>
            {ctaButton1 && (
              <NewCTAButton
                linkComponent={linkComponent}
                title={ctaButton1.text}
                link={{
                  externalUrl: ctaButton1.url,
                  className: 'no-text-decoration',
                }}
                buttonColor={ctaButton1.backgroundColor}
                backgroundOpacity={ctaButton1.backgroundOpacity}
                iconColor={ctaButton1.iconColor}
                // @ts-ignore
                fontFamily={ctaButton1?.fontFamily?.fontFamily}
                fontWeight={ctaButton2?.fontFamily?.fontWeight}
                fontSize={ctaButton1.fontSize}
                fontColor={ctaButton1.fontColor}
                borderColor={ctaButton1.borderColor}
                borderWidth={ctaButton1.borderWidth}
                borderRadius={ctaButton1.borderRadius}
                // @ts-ignore
                iconValue={ctaButton1.iconValue}
                onClick={() => analyticsHandler(PauseScreenEvents.ctaClick1)}
              />
            )}
            {ctaButton2 && (
              <NewCTAButton
                linkComponent={linkComponent}
                title={ctaButton2.text}
                link={{
                  externalUrl: ctaButton2.url,
                  className: 'no-text-decoration',
                }}
                buttonColor={ctaButton2.backgroundColor}
                backgroundOpacity={ctaButton2.backgroundOpacity}
                iconColor={ctaButton2.iconColor}
                // @ts-ignore
                fontFamily={ctaButton2?.fontFamily?.fontFamily}
                fontWeight={ctaButton2?.fontFamily?.fontWeight}
                fontSize={ctaButton2.fontSize}
                fontColor={ctaButton2.fontColor}
                borderColor={ctaButton2.borderColor}
                borderWidth={ctaButton2.borderWidth}
                borderRadius={ctaButton2.borderRadius}
                iconValue={ctaButton2.iconValue}
                onClick={() => analyticsHandler(PauseScreenEvents.ctaClick2)}
              />
            )}
          </Styled.ActionsWrapper>
        </Styled.ButtonsContainer>
      </Styled.ButtonsAndSliderWrapper>
      <Styled.ScreenHeader>
        {computedTitle ? <Styled.Title>{computedTitle}</Styled.Title> : null}
        {onReplay && (
          <Styled.ReplayButton
            backgroundColor={videoControlButtons?.backgroundColor}
            backgroundOpacity={`${generateOpacity(videoControlButtons?.backgroundOpacity)}`}
            iconColor={videoControlButtons?.iconsColor}
            onClick={stopPropagation(handleReplay)}
          >
            <Rotate />
          </Styled.ReplayButton>
        )}
        {canShowShareMoment && (
          <NewActionButton
            showMobileVersion={showMobileVersion}
            title="Share Moment"
            iconName={'ShareBtn'}
            transparent
            onClick={() => {
              analyticsHandler(PauseScreenEvents.share);
              // Share modal will be visible only if video is provided - canShowShareMoment
              // added just for TS
              if (video) {
                showModal(video, moment);
              }
            }}
            buttonBackground={videoControlButtons?.backgroundColor}
            buttonBackgroundOpacity={`${generateOpacity(videoControlButtons?.backgroundOpacity)}`}
            iconsColor={videoControlButtons?.iconsColor}
          />
        )}
        {showWatchFullVideo && (
          <NewActionButton
            showMobileVersion={showMobileVersion}
            onClick={() => {
              analyticsHandler(PauseScreenEvents.watchFullVideo);
              customActionWatchFullVideo && customActionWatchFullVideo();
            }}
            linkComponent={linkComponent}
            title="Watch Full Video"
            link={video}
            iconName={'WatchFull'}
            buttonBackground={videoControlButtons?.backgroundColor}
            buttonBackgroundOpacity={`${generateOpacity(videoControlButtons?.backgroundOpacity)}`}
            iconsColor={videoControlButtons?.iconsColor}
          />
        )}
        {canShowWatchNextMomentButton && (
          <NewActionButton
            showMobileVersion={showMobileVersion}
            linkComponent={linkComponent}
            title="Watch Next Moment"
            link={nextMoment}
            iconName={'NextMoment'}
            onClick={() => {
              analyticsHandler(PauseScreenEvents.watchNextMoment);
              nextMomentHandler && nextMomentHandler();
            }}
            buttonBackground={videoControlButtons?.backgroundColor}
            buttonBackgroundOpacity={`${generateOpacity(videoControlButtons?.backgroundOpacity)}`}
            iconsColor={videoControlButtons?.iconsColor}
          />
        )}
      </Styled.ScreenHeader>
    </Styled.Container>
  ) : null;
}
