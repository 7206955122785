import styled from 'styled-components';
import { th } from '../../../styles/theming/helpers';

export const Container = styled.span`
  font-size: 14px;
  margin-right: 0;
  margin-left: auto;
`;

export const Label = styled.span`
  color: ${th('fontSecondary')};
  padding-right: 5px;
`;

export const Name = styled.span`
  cursor: pointer;
  font-weight: 500;
`;
