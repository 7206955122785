import React from 'react';

import { LinkableProps, LinkController, LinkItem } from '../../Utils/Link';

import * as Styled from './NewActionButton.styles';
import * as icons from '../../../assets/icons';
import { SupportedIconType } from '../../../types';
import { noop, stopPropagation } from '../../../utils/common';

export interface NewActionButtonProps extends LinkableProps, Styled.ActionButtonWrapperProps {
  link?: LinkItem;
  title: string;
  subtitle?: string;
  iconName?: SupportedIconType;
  iconColor?: string;
  onClick?: React.MouseEventHandler;
  fontSize?: number;
  fontName?: string;
  fontWeight?: string;
  buttonColor?: string;
  showMobileVersion?: boolean;
  showButtonLabel?: boolean;
  iconVersion?: boolean;
  customIconColor?: string;
  buttonBackground?: string;
  iconsColor?: string;
}

interface NewActionButtonWrapper extends LinkableProps {
  link?: LinkItem;
}

const ActionButtonContainer: React.FC<NewActionButtonWrapper> = ({
  link,
  linkComponent,
  children,
}) => {
  const Wrapper = link ? LinkController : React.Fragment;

  return link ? (
    <Wrapper component={linkComponent} item={link}>
      {children}
    </Wrapper>
  ) : (
    <>{children}</>
  );
};

export const NewActionButton: React.FC<NewActionButtonProps> = ({
  linkComponent,
  link,
  transparent,
  hideOnMobile,
  title,
  subtitle,
  iconName,
  onClick = noop,
  fontSize,
  fontName,
  fontWeight,
  showMobileVersion,
  showButtonLabel = true,
  iconVersion,
  buttonBackground,
  buttonBackgroundOpacity,
  iconsColor,
}) => {
  const Icon = iconName ? icons[iconName] : undefined;
  return (
    <ActionButtonContainer linkComponent={linkComponent} link={link}>
      <Styled.ActionButtonWrapper
        as={link ? 'span' : undefined}
        onClick={stopPropagation(onClick)}
        transparent={transparent}
        fontSize={fontSize}
        fontName={fontName}
        fontWeight={fontWeight}
        aria-label={title}
        showMobileVersion={showMobileVersion}
        iconVersion={iconVersion}
        buttonBackground={buttonBackground}
        buttonBackgroundOpacity={buttonBackgroundOpacity}
        iconsColor={iconsColor}
      >
        {Icon ? (
          <Icon
            role="presentation"
            focusable="false"
            style={{ marginRight: showButtonLabel ? '10px' : '0' }}
          />
        ) : null}
      </Styled.ActionButtonWrapper>
    </ActionButtonContainer>
  );
};
