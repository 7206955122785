import { css } from 'styled-components';
import { th } from './theming/helpers';

import { TABLET_QUERY, MOBILE_QUERY } from './breakpoints';

export const easeGradient = css`
  linear-gradient(
    to bottom,
    hsla(0, 0%, 0%, 0) 0%,
    hsla(0, 0%, 0%, 0.009) 8.1%,
    hsla(0, 0%, 0%, 0.033) 15.5%,
    hsla(0, 0%, 0%, 0.07) 22.5%,
    hsla(0, 0%, 0%, 0.118) 29%,
    hsla(0, 0%, 0%, 0.174) 35.3%,
    hsla(0, 0%, 0%, 0.236) 41.2%,
    hsla(0, 0%, 0%, 0.302) 47.1%,
    hsla(0, 0%, 0%, 0.368) 52.9%,
    hsla(0, 0%, 0%, 0.434) 58.8%,
    hsla(0, 0%, 0%, 0.496) 64.7%,
    hsla(0, 0%, 0%, 0.552) 71%,
    hsla(0, 0%, 0%, 0.6) 77.5%,
    hsla(0, 0%, 0%, 0.637) 84.5%,
    hsla(0, 0%, 0%, 0.661) 91.9%,
    hsla(0, 0%, 0%, 0.67) 100%
  );
`;

export const easeGradientSlider = (dir = 'left') => css`
  linear-gradient(
    to ${dir},
    ${th('bg', 1)} 0%,
    ${th('bg', 0.997)} 7.9%,
    ${th('bg', 0.989)} 15.7%,
    ${th('bg', 0.974)} 23.3%,
    ${th('bg', 0.951)} 30.7%,
    ${th('bg', 0.921)} 37.9%,
    ${th('bg', 0.881)} 45%,
    ${th('bg', 0.832)} 51.9%,
    ${th('bg', 0.773)} 58.6%,
    ${th('bg', 0.702)} 65.1%,
    ${th('bg', 0.619)} 71.4%,
    ${th('bg', 0.524)} 77.5%,
    ${th('bg', 0.415)} 83.5%,
    ${th('bg', 0.292)} 89.2%,
    ${th('bg', 0.154)} 94.7%,
    ${th('bg', 0)} 100%
  );
`;

export const dot = css`
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: ${th('primary')};
`;

export const fadingElem = (animTime: number, maxHeight = 600) => css<{ isShowed?: boolean }>`
  opacity: ${(props) => (props.isShowed ? 1 : 0)};
  max-height: ${(props) => (props.isShowed ? `${maxHeight}px` : 0)};
  overflow: ${(props) => (props.isShowed ? 'visible' : 'hidden')};
  transition: ${(props) =>
    !props.isShowed
      ? `opacity ${animTime}s ease, max-height 0s linear ${animTime}s`
      : `opacity ${animTime}s ease 0.01s, max-height 0s linear`};
`;

export const momentImageStyle = css`
  position: relative;
  overflow: hidden;
  border-radius: 15px;
`;

export const onlyTablet = (display = 'block') => css`
  display: none;

  ${TABLET_QUERY} {
    display: ${display};
  }
`;

export const onlyMobile = (display = 'block') => css`
  display: none;

  ${MOBILE_QUERY} {
    display: ${display};
  }
`;

export const onlyDesktop = (display = 'block') => css`
  display: ${display};

  ${TABLET_QUERY} {
    display: none;
  }
`;
