import React, { useEffect, useRef, useState } from 'react';
import { ActionButton } from '../common/ActionButton';
import * as Styled from './CustomizablePauseScreen.styles';
import { LinkableProps, LinkItem } from '../Utils/Link';
import {
  CallToActionButtonPauseScreen,
  ObjectSliderItem,
  PauseScreenEventsHandler,
  PauseScreenEvents,
  PromoCodeBox,
  VideoItemMoment,
  VideoItemVideo,
  SocialMediaTypes,
  socialIconsMap,
} from '../../types';
import { ShareItem, useShareModal } from '../ShareModal';
import { PromoCode } from './PromoCode';
import { Rotate } from '../../assets/icons';
import { noop, stopPropagation } from '../../utils/common';

export interface CustomizablePauseScreenProps<
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
> extends LinkableProps {
  title?: string;
  objects: ObjectSliderItem[];
  // video object is needed only if the base player is displaying a moment (but still is optional)
  video?: TVideo;
  moment?: TMoment;
  nextMoment?: LinkItem;
  nextMomentHandler?: () => void;
  onReplay?: () => void;
  isVisible: boolean;
  replay?: () => void;
  showShareMoment?: boolean;
  showWatchFullVideo?: boolean;
  showWatchNextMomentButton?: boolean;
  promoCode?: PromoCodeBox;
  callToActionButton?: CallToActionButtonPauseScreen;
  justifyContent?: string;
  analyticsHandler?: PauseScreenEventsHandler;
  showMobileVersion?: boolean;
  socialMediaButtons?: SocialMediaTypes[];
  customActionWatchFullVideo?: () => void;
}

export function CustomizablePauseScreen<
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
>({
  linkComponent,
  objects,
  video,
  moment,
  nextMoment,
  nextMomentHandler,
  title,
  onReplay,
  isVisible,
  showShareMoment = true,
  showWatchFullVideo = true,
  showWatchNextMomentButton = true,
  promoCode,
  callToActionButton,
  justifyContent,
  analyticsHandler = noop,
  showMobileVersion,
  socialMediaButtons = [],
  customActionWatchFullVideo = noop,
}: CustomizablePauseScreenProps<TVideo, TMoment>) {
  const [showScrollBar, setShowScrollBar] = useState(false);
  const { showModal } = useShareModal<TVideo, TMoment>();
  const canShowShareMoment = showShareMoment;
  const canShowWatchNextMomentButton =
    (nextMoment || nextMomentHandler) && showWatchNextMomentButton;
  const scrollableElement = useRef<HTMLDivElement>(null);
  const contentToScroll = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollableElement.current && contentToScroll.current) {
      setShowScrollBar(
        scrollableElement.current.clientHeight > contentToScroll.current.clientHeight,
      );
    }
  }, [contentToScroll, scrollableElement]);

  const computedTitle = title || moment?.title;

  return isVisible ? (
    <Styled.Container showMobileVersion={showMobileVersion} showScrollBar={showScrollBar}>
      <Styled.ScreenHeader>
        {computedTitle ? <Styled.Title>{computedTitle}</Styled.Title> : null}
        {onReplay && (
          <Styled.ReplayButton onClick={stopPropagation(onReplay)}>
            <Rotate />
            <span>Replay</span>
          </Styled.ReplayButton>
        )}
      </Styled.ScreenHeader>
      <Styled.ButtonsAndSliderWrapper ref={scrollableElement} showScrollBar={showScrollBar}>
        <Styled.ButtonsContainer ref={contentToScroll}>
          <div>
            {/*
              This empty div element is only to keep CTA buttons at the middle of screen,
              and also to keep social media buttons at the bottom of player.
            */}
          </div>
          <div>
            {canShowShareMoment || canShowWatchNextMomentButton || showWatchFullVideo ? (
              <Styled.ActionsWrapper justifyContent={justifyContent}>
                {canShowShareMoment && (
                  <ActionButton
                    showMobileVersion={showMobileVersion}
                    title="Share Moment"
                    iconName={'ShareBg'}
                    transparent
                    onClick={() => {
                      analyticsHandler(PauseScreenEvents.share);
                      // Share modal will be visible only if video is provided - canShowShareMoment
                      // added just for TS
                      if (video) {
                        showModal(video, moment);
                      }
                    }}
                  />
                )}
                {showWatchFullVideo && (
                  <ActionButton
                    showMobileVersion={showMobileVersion}
                    onClick={() => {
                      analyticsHandler(PauseScreenEvents.watchFullVideo);
                      customActionWatchFullVideo && customActionWatchFullVideo();
                    }}
                    linkComponent={linkComponent}
                    title="Watch Full Video"
                    link={video}
                    iconName={'PlayBg'}
                  />
                )}
                {canShowWatchNextMomentButton && (
                  <ActionButton
                    showMobileVersion={showMobileVersion}
                    linkComponent={linkComponent}
                    title="Watch Next Moment"
                    link={nextMoment}
                    iconName={'NextBg'}
                    onClick={() => {
                      analyticsHandler(PauseScreenEvents.watchNextMoment);
                      nextMomentHandler && nextMomentHandler();
                    }}
                  />
                )}
              </Styled.ActionsWrapper>
            ) : null}
            {callToActionButton || promoCode ? (
              <Styled.ActionsWrapper>
                {promoCode && (
                  <PromoCode
                    {...promoCode}
                    showMobileVersion={showMobileVersion}
                    onCopy={() => {
                      analyticsHandler(PauseScreenEvents.promoCode);
                      promoCode.onCopy();
                    }}
                  />
                )}
                {callToActionButton && (
                  <ActionButton
                    showMobileVersion={showMobileVersion}
                    linkComponent={linkComponent}
                    title={callToActionButton.title}
                    link={{
                      externalUrl: callToActionButton.actionUrl,
                      className: 'no-text-decoration',
                    }}
                    onClick={() => {
                      analyticsHandler(PauseScreenEvents.ctaClick);
                      callToActionButton.clickHandler && callToActionButton.clickHandler();
                    }}
                    buttonColor={callToActionButton.buttonColor}
                    iconName={callToActionButton.iconName || 'CartPlus'}
                    iconColor={callToActionButton.iconColor}
                    fontName={callToActionButton.fontName}
                    fontWeight={callToActionButton.fontWeight}
                    fontSize={callToActionButton.fontSize}
                  />
                )}
              </Styled.ActionsWrapper>
            ) : null}
          </div>
          <Styled.ActionsWrapper removeMarginBottom={true}>
            {socialMediaButtons?.length
              ? socialMediaButtons.map((social) => (
                  <ActionButton
                    key={social.id}
                    linkComponent={linkComponent}
                    title={social.label}
                    link={{ externalUrl: social.accountUrl }}
                    iconName={socialIconsMap[social.platformName]}
                    showMobileVersion={showMobileVersion}
                    showButtonLabel={false}
                    transparent={true}
                    iconVersion={true}
                    customIconColor="#fff"
                  />
                ))
              : null}
          </Styled.ActionsWrapper>
        </Styled.ButtonsContainer>
      </Styled.ButtonsAndSliderWrapper>
    </Styled.Container>
  ) : null;
}
