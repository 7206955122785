import styled from 'styled-components';
import { th } from '../../../styles/theming/helpers';

import { LayoutContainer } from '../../Layout/styles';
import variants from '../../../helpers/variants';
import { TABLET_QUERY, LAYOUT_QUERY, LAYOUT_WIDTH } from '../../../styles/breakpoints';
import { easeGradientSlider } from '../../../styles/helpers';

const OVERLAP_WIDTH = 150;

const variantArrowSize = variants({
  variantName: 'arrowSize',
  variantsTable: {
    mini: {
      size: 25,
      offset: 12,
      edgeOffset: 0,
    },
    normal: {
      size: 34,
      offset: 30,
      edgeOffset: 90,
    },
  },
  defaultVariant: 'normal',
});

const variantBottomSpace = variants({
  variantName: 'bottomSpace',
  variantsTable: {
    none: {
      size: 0,
      sizeTablet: 0,
    },
    normal: {
      size: 25,
      sizeTablet: 10,
    },
  },
  defaultVariant: 'normal',
});

export const Container = styled.div`
  position: relative;
`;

export const SliderContainer = styled.div`
  display: flex;
`;

export const Wrapper = styled.div<{ withoutGradient?: boolean }>`
  margin: 0 -1.25%;

  ${TABLET_QUERY} {
    margin: ${({ withoutGradient }) => (withoutGradient ? `0` : '0 -10px')};
  }
`;

export const LayoutContainerWithOverlaps = styled(LayoutContainer)<{
  tabletHeight: number;
  withoutGradient?: boolean;
}>`
  position: relative;

  .slick-track {
    /* FIXME: find out why it's needed */
    display: flex;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    width: ${OVERLAP_WIDTH}px;
    z-index: 4;
  }

  &:after {
    left: 100%;
    background: ${easeGradientSlider()};
  }

  &:before {
    right: 100%;
    background: ${easeGradientSlider('right')};
  }

  ${TABLET_QUERY} {
    height: ${(props) => props.tabletHeight}px;
    padding: 0;

    &:before,
    &:after {
      display: none;
    }
  }

  ${({ withoutGradient }) =>
    withoutGradient &&
    `
    padding: 0;

    ${TABLET_QUERY} {
      overflow-x: auto !important;
    }

    &:before,
    &:after {
      display: none;
    }
  `}
`;

export const ArrowBase = styled.button`
  display: none;
  border: none;
  border-radius: 8px;
  background: ${th('primary')};
  z-index: 5;
  color: ${th('invertFont')};

  position: absolute;
  top: 50%;

  svg {
    width: 7px;
    height: 12px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &:before {
    display: none;
  }
`;

export const ArrowLeft = styled(ArrowBase)`
  transform: rotate(-180deg);

  ${LAYOUT_QUERY} {
    left: 0px;
  }
`;

export const ArrowRight = styled(ArrowBase)``;

interface SliderWrapperProps {
  withoutGradient?: boolean;
  isTop?: boolean;
  arrowSize?: 'mini' | 'normal';
  bottomSpace?: 'none' | 'normal';
  stickyNavigationArrows?: boolean;
}

export const SliderWrapper = styled.div<SliderWrapperProps>`
  max-width: ${LAYOUT_WIDTH + 2 * OVERLAP_WIDTH}px;
  margin: 0 auto;
  overflow: ${({ withoutGradient }) => (withoutGradient ? 'visible' : 'hidden')};
  padding: ${(props) => (props.isTop ? 0 : 40)}px 0 ${variantBottomSpace('size')}px;

  ${TABLET_QUERY} {
    padding-bottom: ${variantBottomSpace('sizeTablet')}px;
  }

  ${ArrowBase} {
    width: ${variantArrowSize('size')}px;
    height: ${variantArrowSize('size')}px;
    margin-top: -${variantArrowSize('offset')}px;
  }

  ${ArrowLeft} {
    left: -${variantArrowSize('edgeOffset')}px;

    ${LAYOUT_QUERY} {
      left: 0px;
    }
  }

  ${ArrowRight} {
    right: -${variantArrowSize('edgeOffset')}px;

    ${LAYOUT_QUERY} {
      right: 0px;
    }
  }

  .bar-paging {
    top: -45px;
    right: 0;
    position: absolute;
    list-style: none;
    display: flex !important;
    flex-direction: row;
    right: 16px;

    li {
      .bar {
        width: 20px;
        height: 4px;
        border-radius: 3.5px;
        background-color: ${th('outline')};
        margin-left: 6px;
        border-radius: 4px;
        cursor: pointer;
      }

      &.slick-active {
        .bar {
          background: ${th('primary')};
        }
      }
    }
  }

  ${({ withoutGradient }) =>
    withoutGradient &&
    `
    padding: 0;
    margin: 0 -10px;

    .pause-screen-slider {
      ${TABLET_QUERY} {
        padding: 0 50px 0 10%;
      }

      .slick-list {
        overflow: hidden;
      }
    }
  `}

  ${({ stickyNavigationArrows }) =>
    stickyNavigationArrows &&
    `
    ${ArrowLeft} {
      left: 0px;
    }

    ${ArrowRight} {
      right: 0px;
    }
  `}
`;
