import React from 'react';
import styled from 'styled-components';
import { Tooltip, TooltipProps } from '../../components/common/Tooltip';
import { usePlayerContext } from '../Player/PlayerContext';

import { Nullable } from '../../utils/types';

interface Props extends PopoverProps {
  menu: React.ReactNode;
  children: React.ReactElement;
}

export const PlayerMenu: React.FC<Props> = ({ menu, children, ...popoverProps }) => {
  const { playerContainerRef } = usePlayerContext();

  return (
    /**
     * Additional div is needed around the reference element to create a new context for the
     * tooltip, so that it can be attached directly after the reference element in the DOM
     * structure.
     *
     * Otherwise, a flat structure like this:
     * ```js
     * <Popover content="tooltip 1"> <button>text 1</button> </Popover>
     * <Popover content="tooltip 2"> <button>text 2</button> </Popover>
     * <Popover content="tooltip 3"> <button>text 3</button> </Popover>
     * ```
     *
     * Would result in DOM looking like:
     *
     * ```html
     * <button>text 1</button>
     * <button>text 2</button>
     * <button>text 3</button>
     * <div>tooltip 1</div>
     * <div>tooltip 2</div>
     * <div>tooltip 3</div>
     * ```
     */
    <ReferenceWrapper>
      <Popover
        content={
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onKeyDown={(event) => {
              // Prevent keyboard shortcuts when control panel element is focused to prevent from
              // firing multiple actions at once (one for locally focused element and the other for
              // global video shortcuts)
              event.stopPropagation();
            }}
          >
            {menu}
          </div>
        }
        {...popoverProps}
        boundary={playerContainerRef}
      >
        {children}
      </Popover>
    </ReferenceWrapper>
  );
};

interface PopoverProps extends TooltipProps {
  boundary?: Nullable<HTMLElement>;
}

const Popover: React.VFC<PopoverProps> = ({ boundary, ...props }) => {
  return (
    <StyledTooltip
      placement="top"
      trigger="focus"
      {...props}
      interactive={true}
      popperOptions={{
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: boundary,
              padding: 12,
            },
          },
        ],
      }}
      arrow={false}
      maxWidth="auto"
    />
  );
};

const StyledTooltip = styled(Tooltip)`
  background: rgba(0, 0, 0, 0.75);
  border-radius: 3px;

  .tippy-content {
    padding: 0;
  }
`;

const ReferenceWrapper = styled.div`
  display: flex;
  align-items: center;
`;
