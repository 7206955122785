import React, { forwardRef } from 'react';

import { Settings as SlickSliderSettings } from 'react-slick';

import { Container, SliderWrapper, LayoutContainerWithOverlaps } from './style';

import SlickSlider from './SlickSlider';
import VirtualHorizontalList from './VirtualHorizontalList';
import { useDevice } from '../../../hooks/useDevice';

interface SliderProps {
  top?: JSX.Element;
  bottom?: JSX.Element;
  sliderSettings: SlickSliderSettings;
  currentSlide: number;
  bottomSpace?: 'none' | 'normal';
  arrowSize?: 'mini' | 'normal';
  withoutGradient?: boolean;
  tabletSizes: {
    width: number;
    height: number;
  };
  stickyNavigationArrows?: boolean;
}

export const Slider = forwardRef<any, SliderProps & { children: React.ReactNode }>(
  (
    {
      children,
      sliderSettings,
      top,
      bottom,
      currentSlide,
      arrowSize,
      bottomSpace,
      withoutGradient,
      tabletSizes,
      stickyNavigationArrows,
    },
    containerRef,
  ) => {
    const { isTablet } = useDevice();

    return (
      <Container onTouchMove={(event) => event.preventDefault()}>
        <SliderWrapper
          isTop={!!top}
          arrowSize={arrowSize}
          bottomSpace={bottomSpace}
          withoutGradient={withoutGradient}
          stickyNavigationArrows={stickyNavigationArrows}
        >
          {top}
          <LayoutContainerWithOverlaps
            ref={containerRef}
            withoutGradient={withoutGradient}
            tabletHeight={tabletSizes?.height}
          >
            {isTablet ? (
              tabletSizes && (
                <VirtualHorizontalList itemSize={tabletSizes.width} currentSlide={currentSlide}>
                  {children}
                </VirtualHorizontalList>
              )
            ) : (
              <SlickSlider
                withoutGradient={withoutGradient}
                currentSlide={currentSlide}
                sliderSettings={sliderSettings}
              >
                {children}
              </SlickSlider>
            )}
          </LayoutContainerWithOverlaps>
          {bottom}
        </SliderWrapper>
      </Container>
    );
  },
);
