/*
 * Creating variant for styled-component. Example of use is in `./variants.md`
 */

const variants = ({
  variantName,
  variantsTable,
  defaultVariant,
}: {
  variantName: string;
  variantsTable: any;
  defaultVariant: string;
}) => (propsName: string) => (props: any) => {
  return variantsTable[props[variantName] || defaultVariant][propsName];
};

export default variants;
