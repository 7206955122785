import React from 'react';
import debounce from 'lodash.debounce';
import { window } from 'ssr-window';

import { MOBILE, TABLET, LAYOUT_WIDTH } from '../styles/breakpoints';

export function useDevice() {
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  const handleResize = React.useCallback(
    debounce(
      () => {
        setScreenWidth(window.innerWidth);
      },
      300,
      { leading: true },
    ),
    [],
  );

  React.useEffect(() => {
    handleResize();
    window.addEventListener && window.addEventListener('resize', handleResize);

    return () => {
      handleResize.cancel();
      window.removeEventListener && window.removeEventListener('resize', handleResize);
    };
  }, [handleResize]);

  const checkSize = (breakpoint: number) => screenWidth && breakpoint >= screenWidth;

  return {
    isMobile: checkSize(MOBILE),
    isTablet: checkSize(TABLET),
    isUnderContainer: checkSize(LAYOUT_WIDTH),
  };
}
