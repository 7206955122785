import styled from 'styled-components';
import { th } from '../../../styles/theming/helpers';

export const Container = styled.div`
  font-size: 13px;
  line-height: 1;
  display: inline-block;
  margin: 0 10px 10px 0;
  padding: 9px 13px;
  background: ${th('outline')};
  border-radius: 20px;
`;
