import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { ObjectSliderItem, VideoItemMoment } from '../../types';
import { ExternalContentSliderElement } from './ExternalContentSliderElement';
import { GoArrow, Close } from './../../assets/icons';
import { useDevice } from '../../hooks/useDevice';

import * as Styled from './ExternalContentSlider.styles';

interface ExternalContentButtonsProps {
  className?: string;
  onClick: () => void;
}

const ExternalContentSliderNext: React.FC<ExternalContentButtonsProps> = ({
  className,
  onClick,
}) => {
  return (
    <Styled.NextSlide onClick={onClick} className={className}>
      <GoArrow className="next-slide-icon" />
    </Styled.NextSlide>
  );
};

const ExternalContentSliderPrev: React.FC<ExternalContentButtonsProps> = ({
  onClick,
  className,
}) => {
  return (
    <Styled.PrevSlide onClick={onClick} className={className}>
      <GoArrow className="prev-slide-icon" />
    </Styled.PrevSlide>
  );
};

interface ExternalContentSliderProps {
  objects: ObjectSliderItem[];
  callToActionTitle?: string;
  showAtStart?: boolean;
  showAtEnd?: boolean;
  isPlaying: boolean;
  /**
   * Pass currentTime for Videos or currentMomentTime for Moments
   */
  currentTime: number;
  duration: number;
  isEnded: boolean;
  /**
   * When passed, currentTime prop value should be currentMomentTime coming from playerContext
   */
  currentMoment?: VideoItemMoment;
}

export const ExternalContentSlider: React.FC<ExternalContentSliderProps> = ({
  objects,
  callToActionTitle = 'Products',
  showAtStart = false,
  showAtEnd = false,
  isPlaying,
  currentTime,
  duration,
  isEnded,
  currentMoment,
}) => {
  const currentMomentDuration = currentMoment
    ? currentMoment.endTimestamp - currentMoment.startTimestamp
    : 0;

  // There's entire mechnism implemented to work properly with intro/outro for Moments
  // But as of Jacob Coby request we don't show it
  const canShowIntroOutro = !currentMoment;

  // show intro/outro for any video and moments longer than 20s (disabled as per comment above)
  // const canShowIntroOutro = currentMoment ? currentMomentDuration > 20 : true;

  const [isProductsListVisible, setIsProductsListVisible] = useState(
    canShowIntroOutro && showAtStart,
  );
  const [isProductsListManuallyToggled, setIsProductsListManuallyToggled] = useState(false);

  const [isIntroPlaying, setIsIntroPlaying] = useState(false);
  const [isOutroPlaying, setIsOutroPlaying] = useState(false);
  const [isIntroFinished, setIsIntroFinished] = useState(false);
  // const [isOutroFinished, setIsOutroFinished] = useState(false);

  const sliderRef = useRef<Slider>(null);
  const { isMobile } = useDevice();

  const introDuration = currentMoment ? 3 : 5;
  const outroStartTimestamp = (currentMoment ? currentMomentDuration : duration) - introDuration;

  useEffect(() => {
    if (!canShowIntroOutro) {
      return;
    }

    if (showAtStart && !isIntroFinished) {
      if (introDuration > currentTime) {
        // Show intro when in range
        setIsIntroPlaying(true);
      } else {
        // Hide and don't show again if skipped outside of range
        setIsIntroPlaying(false);
        setIsIntroFinished(true);
      }
    }

    if (showAtEnd /* && !isOutroFinished */) {
      if (outroStartTimestamp < currentTime) {
        // Show outro when in range
        setIsOutroPlaying(true);
      } else {
        if (isOutroPlaying) {
          // Hide when outside of range
          setIsOutroPlaying(false);
        }
        if (isEnded) {
          // Don't show again only when watched till the end
          // setIsOutroFinished(true);
        }
      }
    }
  }, [
    canShowIntroOutro,
    currentTime,
    introDuration,
    outroStartTimestamp,
    isEnded,
    isIntroFinished,
    // isOutroFinished
    isOutroPlaying,
    showAtStart,
    showAtEnd,
  ]);

  useEffect(() => {
    if (!isProductsListManuallyToggled && currentTime) {
      if (isIntroPlaying || isOutroPlaying) {
        setIsProductsListVisible(true);
      } else {
        // When not promoted don't toggle at the end when video stopped
        if (!showAtEnd && isEnded) {
          return;
        }

        setIsProductsListVisible(!isPlaying);
      }
    }
    // We don't want to refresh on every currentItemTime change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isProductsListManuallyToggled, isIntroPlaying, isOutroPlaying]);

  const handleProductsToggle = () => {
    setIsProductsListManuallyToggled(true);
    setIsProductsListVisible((isVisible) => !isVisible);
  };

  return (
    <>
      {!isProductsListVisible ? (
        <Styled.Button onClick={handleProductsToggle}>
          {callToActionTitle}
          <GoArrow className="go-arrow-icon" />
        </Styled.Button>
      ) : null}
      <Styled.Products style={{ display: isProductsListVisible ? 'block' : 'none' }}>
        <Styled.ProductsHead>
          <Styled.ProductsTitle>{callToActionTitle}</Styled.ProductsTitle>
          <Styled.ProductsClose onClick={handleProductsToggle}>
            <Close className="close-icon" />
          </Styled.ProductsClose>
        </Styled.ProductsHead>
        <Styled.SliderWrapper>
          {isMobile ? (
            <Slider
              dots={false}
              ref={sliderRef}
              infinite={false}
              slidesToScroll={1}
              slidesToShow={3}
              //@ts-ignore
              nextArrow={<ExternalContentSliderNext />}
              //@ts-ignore
              prevArrow={<ExternalContentSliderPrev />}
            >
              {objects.map(({ id, title, imageUrl, url }) => (
                <ExternalContentSliderElement
                  key={id}
                  title={title}
                  imageUrl={imageUrl}
                  link={{ externalUrl: url }}
                />
              ))}
            </Slider>
          ) : (
            objects.map(({ id, title, imageUrl, url }) => (
              <ExternalContentSliderElement
                key={id}
                title={title}
                imageUrl={imageUrl}
                link={{ externalUrl: url }}
              />
            ))
          )}
        </Styled.SliderWrapper>
      </Styled.Products>
    </>
  );
};
