import styled from 'styled-components';
import { TABLET_QUERY } from '../../styles/breakpoints';
import { th } from '../../styles/theming/helpers';
import { BaseLine } from '../PlayerControls/ProgressBar.styles';

export const SlideWrapper = styled.div`
  width: 100%;
  padding-right: 1%;

  cursor: pointer;

  ${TABLET_QUERY} {
    padding-top: 5px;
    width: 110px;
  }
`;

interface ContainerProps {
  shouldCenterThumbnails: boolean;
}

export const Container = styled.div<ContainerProps>`
  ${TABLET_QUERY} {
    background: ${th('bgLight')};
    border-bottom: solid 1px ${th('outline')};

    ${SlideWrapper} ${BaseLine} {
      height: 4px;
    }
  }

  .slick-list {
    display: flex;
    justify-content: ${({ shouldCenterThumbnails }) =>
      shouldCenterThumbnails ? 'center' : 'flex-start'};
  }
`;
