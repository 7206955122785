import styled from 'styled-components';

import { th } from '../../styles/theming/helpers';
import { TABLET_QUERY } from '../../styles/breakpoints';
import { LinkController } from '../Utils/Link';

export const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  ${TABLET_QUERY} {
    margin-bottom: 40px;
  }
`;

export const Heading = styled.h1`
  color: ${th('fontDarker')};
  font-family: ${th('headingFontFamily')};
  font-weight: ${th('headingFontWeight')};
  font-size: 30px;

  ${TABLET_QUERY} {
    font-size: 24px;
  }
`;

export const SeeMoreMobileWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;

  ${TABLET_QUERY} {
    flex-direction: column;
    margin: 0;
  }
`;

export const PlayerWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

export const Thumbnails = styled.div`
  flex-shrink: 0;
  margin-left: 32px;
  width: 35%;

  ${TABLET_QUERY} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 40px;
    margin: 0;
    width: 100%;
  }
`;

export const ThumbnailWrapper = styled.div`
  display: flex;
  height: 110px;
  position: relative;
  width: 100%;

  &:hover {
    opacity: 0.75;
  }

  :not(last-child) {
    margin-bottom: 32px;
  }

  ${TABLET_QUERY} {
    align-items: flex-start;
    flex-direction: column;
    height: auto;
    justify-content: flex-start;
    margin-bottom: 32px;
    width: 100%;
  }

  a {
    &:hover {
      opacity: 1;
    }
  }
`;
interface ThumbnailProps {
  readonly url?: string;
}

export const Thumbnail = styled.div.attrs<ThumbnailProps>(({ url }) => ({
  style: url ? { backgroundImage: `url(${url})` } : {},
}))<ThumbnailProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${th('gridBorderRadius')};
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56%;
  position: relative;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  flex-shrink: 0;

  ${TABLET_QUERY} {
    width: 100%;
  }
`;

export const VideoDescription = styled.article`
  margin-top: 20px;
  section {
    color: ${th('paragraphFont')};
    font-size: 15px;
    line-height: 25px;
  }
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  @media only screen and (max-width: 886px) {
    margin-bottom: 2.5rem;
  }
`;

export const ShareButtonWrapper = styled.div`
  margin: 15px 10px 0 10px;
`;

export const ShareButtonAbsoluteWrapper = styled.div`
  height: 45px;
  width: 45px;
  position: absolute;
  right: 0;
  top: 0;
`;

export const ThumbnailLinkController = styled(LinkController)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
  width: 100%;
`;
