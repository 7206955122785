import { css } from 'styled-components';
import { th } from './theming';

export const resetButton = css`
  background-color: transparent;
  border: 0;
  padding: 0;
  color: inherit;
  text-align: left;

  &:not(:disabled) {
    cursor: pointer;
  }
`;

export const mainOutline = css`
  outline: 2px auto ${th('primary')};
  outline-offset: 3px;
`;
