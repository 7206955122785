import { isBrowser } from './browser';

function setValue(key: string, value: any) {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (e) {
    isBrowser && console.warn(`Value "${value}" cannot be saved in local store under key "${key}"`);
  }
}

function getValue<T>(key: string): T | undefined;
function getValue<T>(key: string, fallback: T): T;
function getValue<T>(key: string, fallback?: T): T | undefined {
  try {
    const value = window.localStorage.getItem(key);
    return value ? (JSON.parse(value) as T) : fallback;
  } catch (e) {
    isBrowser && console.warn(`Value under "${key}" key cannot be read`);
    return fallback;
  }
}

function removeValue(key: string) {
  try {
    return window.localStorage.removeItem(key);
  } catch (e) {
    isBrowser && console.warn(`Value under "${key}" key cannot be removed`);
  }
}

export const LocalStorage = {
  set: setValue,
  get: getValue,
  remove: removeValue,
};
