import { ObjectAnnotation, ObjectAnnotationCategory } from '../../types';

export interface objectCategoriesInterface {
  name: ObjectAnnotationCategory;
  title: string;
  objects: ObjectAnnotation[];
}

export const objectCategories = [
  { name: ObjectAnnotationCategory.Person, title: 'People', objects: [] },
  { name: ObjectAnnotationCategory.Product, title: 'Promoted products', objects: [] },
  { name: ObjectAnnotationCategory.Location, title: 'Locations', objects: [] },
  { name: ObjectAnnotationCategory.Other, title: 'Other', objects: [] },
];

export const objectCategoriesActionNames = {
  [ObjectAnnotationCategory.Person]: {
    name: 'More',
    title: 'More about',
  },
  [ObjectAnnotationCategory.Product]: {
    name: 'Buy now',
    title: 'Buy now',
  },
  [ObjectAnnotationCategory.Location]: {
    name: 'See on a map',
    title: 'See on a map',
  },
  [ObjectAnnotationCategory.Other]: {
    name: 'More',
    title: 'More about',
  },
};
