import styled from 'styled-components';

import { dot } from '../../../styles/helpers';

interface ContainerProps {
  space: number;
}

export const Container = styled.div<ContainerProps>`
  ${dot};
  margin: 0 ${(props) => props.space * 8}px;
`;
