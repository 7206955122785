import styled from 'styled-components';

import { MOBILE_QUERY } from '../../styles/breakpoints';

const borderRadius = 'border-radius: 5px;';
const opacityTransitionDuration = '0.2s';

export const Container = styled.div`
  flex: 2;
  height: 30px;
  margin-right: 15px;
  position: relative;
  cursor: pointer;
  ${borderRadius};

  ${MOBILE_QUERY} {
    margin-left: 15px;
  }
`;

interface LineBaseProps {
  width?: string;
  invisible?: boolean;
}

const LineBase = styled.div.attrs<LineBaseProps>(({ width }) => ({
  style: {
    width,
  },
}))<LineBaseProps>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto 0;
  height: 7px;
  opacity: ${({ invisible }) => (invisible ? 0 : 1)};
  transition: opacity ${opacityTransitionDuration} ease,
    width 0s ease ${({ invisible }) => (invisible ? opacityTransitionDuration : '0s')};
  ${borderRadius};
`;

export const ProgressLine = styled(LineBase)`
  background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.65) 100%);
`;

export const Line = styled(LineBase)`
  background: rgba(255, 255, 255, 0.35);
`;

export const BaseLine = styled(LineBase)`
  background: rgba(255, 255, 255, 0.35);
  width: 100%;
  overflow: hidden;
`;
