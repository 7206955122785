import styled from 'styled-components';
import { th } from '../../../styles/theming/helpers';

export const Container = styled.div`
  background: ${th('bgSecondary', 0.6)};
  color: ${th('invertFont')};
  font-size: 13px;

  display: flex;
  justify-content: space-around;
  align-items: center;

  height: 26px;
  width: 53px;
  border-radius: 13px;

  position: absolute;
  right: 5px;
  bottom: 5px;
`;
