import React, { useRef } from 'react';

import { SliderElement } from './SliderElement';
import { Slider } from '../common/Slider';
import useBoundingClientRect from '../../hooks/useBoundingClientRect';

import { SlideWrapper, Container } from './VideoSlider.style';
import { VideoItem, VideoItemType } from '../../types';
import { VARIANTS } from './SliderElement/SliderElement.styles';
import { LinkableProps, LinkController, OnLinkClick } from '../Utils/Link';
import { noop } from '../../utils/common';
import styled from 'styled-components';

const TABLET_SIZES = { width: 110, height: 100 };

export type SliderItem = Pick<VideoItem, 'title' | 'thumbnailUrl' | 'id' | 'slug'>;

export interface VideoSliderProps extends LinkableProps {
  items: SliderItem[];
  activeItems: string[];
  slidesToShow?: number;
  size?: VARIANTS;
  onElementClick?: OnLinkClick;
  onSlideClick?: (id: string) => void;
  stickyNavigationArrows?: boolean;
}

export const VideoSlider: React.FC<VideoSliderProps> = ({
  items,
  activeItems,
  slidesToShow = 7,
  size = VARIANTS.mini,
  linkComponent,
  onElementClick = noop,
  onSlideClick = noop,
  stickyNavigationArrows,
}) => {
  const containerRef = useRef<HTMLElement>(null);
  const rect = useBoundingClientRect(containerRef);
  const currentSlide = items.findIndex(({ id }) => activeItems[0] === id) || 0;
  const shouldCenterThumbnails = slidesToShow > items.length;

  return (
    <Container shouldCenterThumbnails={shouldCenterThumbnails}>
      <Slider
        ref={containerRef}
        sliderSettings={{
          dots: false,
          slidesToShow,
          slidesToScroll: slidesToShow,
        }}
        currentSlide={currentSlide}
        tabletSizes={TABLET_SIZES}
        stickyNavigationArrows={stickyNavigationArrows}
      >
        {items.map(({ id, title, thumbnailUrl, slug }) => (
          <VideoSliderElement
            key={id}
            linkComponent={linkComponent}
            id={id}
            slug={slug}
            onSlideClick={onSlideClick}
            onElementClick={onElementClick}
          >
            <SlideWrapper>
              <SliderElement
                title={title}
                thumbnailUrl={thumbnailUrl}
                containerRect={rect}
                size={size}
                active={!!activeItems.find((activeId) => activeId === id)}
              />
            </SlideWrapper>
          </VideoSliderElement>
        ))}
      </Slider>
    </Container>
  );
};

interface VideoSliderElementTypes extends LinkableProps {
  id: string;
  onElementClick?: OnLinkClick;
  slug: string;
  onSlideClick?: (id: string) => void;
  children: React.ReactNode;
}

const VideoSliderElement: React.FC<VideoSliderElementTypes> = ({
  linkComponent,
  id,
  onElementClick,
  slug,
  onSlideClick,
  children,
}) => {
  return linkComponent ? (
    <LinkController
      key={id}
      component={linkComponent}
      item={{ id, type: VideoItemType.Moment, slug }}
      onClick={onElementClick}
    >
      {children}
    </LinkController>
  ) : (
    <StyledSlide onClick={() => onSlideClick?.(id)}>{children}</StyledSlide>
  );
};

const StyledSlide = styled.button`
  width: 100%;
  background: none;
  border: 0;
`;
