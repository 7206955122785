import React from 'react';
import truncate from 'lodash.truncate';

import { LinkableProps, LinkController, LinkItem } from '../../Utils/Link';

import * as Styled from './ActionButton.styles';
import * as icons from '../../../assets/icons';
import { SupportedIconType } from '../../../types';
import { noop, stopPropagation } from '../../../utils/common';

const TITLE_LIMIT = 35;

export interface ActionButtonProps extends LinkableProps, Styled.ActionButtonWrapperProps {
  link?: LinkItem;
  title: string;
  subtitle?: string;
  iconName?: SupportedIconType;
  iconColor?: string;
  onClick?: React.MouseEventHandler;
  fontSize?: number;
  fontName?: string;
  fontWeight?: string;
  buttonColor?: string;
  showMobileVersion?: boolean;
  showButtonLabel?: boolean;
  iconVersion?: boolean;
  customIconColor?: string;
}

interface ActionButtonWrapper extends LinkableProps {
  link?: LinkItem;
}

const ActionButtonContainer: React.FC<ActionButtonWrapper> = ({
  link,
  linkComponent,
  children,
}) => {
  const Wrapper = link ? LinkController : React.Fragment;

  return link ? (
    <Wrapper component={linkComponent} item={link}>
      {children}
    </Wrapper>
  ) : (
    <>{children}</>
  );
};

export const ActionButton: React.FC<ActionButtonProps> = ({
  linkComponent,
  link,
  transparent,
  hideOnMobile,
  title,
  subtitle,
  iconName,
  iconColor,
  onClick = noop,
  fontSize,
  fontName,
  fontWeight,
  buttonColor,
  showMobileVersion,
  showButtonLabel = true,
  iconVersion,
  customIconColor,
}) => {
  const Icon = iconName ? icons[iconName] : undefined;
  return (
    <ActionButtonContainer linkComponent={linkComponent} link={link}>
      <Styled.ActionButtonWrapper
        as={link ? 'span' : undefined}
        onClick={stopPropagation(onClick)}
        transparent={transparent}
        fontSize={fontSize}
        fontName={fontName}
        fontWeight={fontWeight}
        buttonColor={buttonColor}
        iconColor={iconColor}
        aria-label={title}
        showMobileVersion={showMobileVersion}
        iconVersion={iconVersion}
        customIconColor={customIconColor}
      >
        {Icon ? (
          <Icon
            role="presentation"
            focusable="false"
            style={{ marginRight: showButtonLabel ? '10px' : '0' }}
          />
        ) : null}
        {showButtonLabel ? (
          <span>
            {title}
            {subtitle ? (
              <Styled.ActionButtonSubtitle>
                {truncate(subtitle, { length: TITLE_LIMIT })}
              </Styled.ActionButtonSubtitle>
            ) : null}
          </span>
        ) : null}
      </Styled.ActionButtonWrapper>
    </ActionButtonContainer>
  );
};
