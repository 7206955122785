import { ReactComponent as ArrowRight } from '../assets/icons/overlays/arrow-right.svg';
import { ReactComponent as Bookmark } from '../assets/icons/overlays/bookmark.svg';
import { ReactComponent as Cart } from '../assets/icons/overlays/cart.svg';
import { ReactComponent as CheckCircle } from '../assets/icons/overlays/check-circle.svg';
import { ReactComponent as ChevronRight } from '../assets/icons/overlays/chevron-right.svg';
import { ReactComponent as Coffee } from '../assets/icons/overlays/coffee.svg';
import { ReactComponent as Discount } from '../assets/icons/overlays/discount.svg';
import { ReactComponent as DollarSign } from '../assets/icons/overlays/dollar-sign.svg';
import { ReactComponent as Gift } from '../assets/icons/overlays/gift.svg';
import { ReactComponent as Heart } from '../assets/icons/overlays/heart.svg';
import { ReactComponent as InfoCircle } from '../assets/icons/overlays/info-circle.svg';
import { ReactComponent as PlayCircle } from '../assets/icons/overlays/play-circle.svg';
import { ReactComponent as Share } from '../assets/icons/overlays/share.svg';
import { ReactComponent as ShoppingBag } from '../assets/icons/overlays/shopping-bag.svg';
import { ReactComponent as Star } from '../assets/icons/overlays/star.svg';
import { ReactComponent as ShapeCircle } from '../assets/icons/shapes/shape-circle.svg';
import { ReactComponent as ShapeStar } from '../assets/icons/shapes/shape-star.svg';
import { OverlaysCTAButton } from '../types';
import {
  CreateButtonCTAInput,
  CTAItemType,
  CTATimestamp,
  VideoCTATimestampResponse,
} from '../types/VideoTimeStamps';

export enum IconShapes {
  Circle = 'CIRCLE',
  Star = 'STAR',
  Plain = 'PLAIN',
}

export enum Icons {
  ArrowRight = 'ARROW_RIGHT',
  Bookmark = 'BOOKMARK',
  Cart = 'CART',
  CheckCircle = 'CHECK',
  ChevronRight = 'CHEVRON_RIGHT',
  Coffee = 'COFFEE',
  Discount = 'DISCOUNT',
  DollarSign = 'DOLLAR_SIGN',
  Gift = 'GIFT',
  Heart = 'HEART',
  InfoCircle = 'INFO',
  PlayCircle = 'PLAY',
  Share = 'SHARE',
  ShoppingBag = 'SHOPPING_BAG',
  Star = 'STAR',
}

export type IconComponent = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

export interface OverlaysIconOptions {
  label: string;
  value: string;
  Component: IconComponent;
  ShapeComponent: IconComponent | null;
}

export const ICON_OPTIONS = [
  { label: 'Dollar sign', name: Icons.DollarSign },
  { label: 'Info', name: Icons.InfoCircle },
  { label: 'Arrow right', name: Icons.ArrowRight },
  { label: 'Bookmark', name: Icons.Bookmark },
  { label: 'Gift', name: Icons.Gift },
  { label: 'Shopping bag', name: Icons.ShoppingBag },
  { label: 'Chevron right', name: Icons.ChevronRight },
  { label: 'Check', name: Icons.CheckCircle },
  { label: 'Star', name: Icons.Star },
  { label: 'Cart', name: Icons.Cart },
  { label: 'Coffee', name: Icons.Coffee },
  { label: 'Heart', name: Icons.Heart },
  { label: 'Discount', name: Icons.Discount },
  { label: 'Share', name: Icons.Share },
  { label: 'Play', name: Icons.PlayCircle },
];

export const SHAPE_ICON = {
  [IconShapes.Circle]: ShapeCircle,
  [IconShapes.Star]: ShapeStar,
  [IconShapes.Plain]: null,
};

export const SHAPE_LABEL = {
  [IconShapes.Circle]: 'on circle',
  [IconShapes.Star]: 'on star',
  [IconShapes.Plain]: '',
};

export const ICONS = {
  [Icons.DollarSign]: DollarSign,
  [Icons.InfoCircle]: InfoCircle,
  [Icons.ArrowRight]: ArrowRight,
  [Icons.Bookmark]: Bookmark,
  [Icons.Gift]: Gift,
  [Icons.ShoppingBag]: ShoppingBag,
  [Icons.ChevronRight]: ChevronRight,
  [Icons.CheckCircle]: CheckCircle,
  [Icons.Star]: Star,
  [Icons.Cart]: Cart,
  [Icons.Coffee]: Coffee,
  [Icons.Heart]: Heart,
  [Icons.Discount]: Discount,
  [Icons.Share]: Share,
  [Icons.PlayCircle]: PlayCircle,
};

const formatIconOption = (shape: IconShapes, label: string, name: Icons) => {
  return {
    label: `${label} ${SHAPE_LABEL[shape]}`,
    value: `${name}_${shape}`,
    Component: ICONS[name],
    ShapeComponent: SHAPE_ICON[shape],
  };
};

export const getAllIconOptions = () => {
  const options: OverlaysIconOptions[] = [];

  ICON_OPTIONS.forEach(({ label, name }) => {
    const option = [
      formatIconOption(IconShapes.Plain, label, name),
      formatIconOption(IconShapes.Circle, label, name),
      formatIconOption(IconShapes.Star, label, name),
    ];
    // @ts-ignore
    options.push(...option);
  });

  return options;
};

export const EMPTY_INPUT_VALUE = 'None';

export const convertNumericValue = (value: string) => (value === EMPTY_INPUT_VALUE ? '0px' : value);

export const convertButtonDataFromServer = (fields: CreateButtonCTAInput) => {
  return Object.entries(fields).reduce((acc, [key, value]) => {
    if (['background', 'border', 'icon', 'text'].includes(key)) {
      return { ...acc, ...JSON.parse(value) };
    } else {
      return { ...acc, [key]: value };
    }
  }, {}) as OverlaysCTAButton;
};

const msToSeconds = (value: number) => (value === 0 ? 0 : value / 1000);

export const convertTimestampsFromServer = (items: VideoCTATimestampResponse[]): CTATimestamp[] => {
  return items.map(({ startTimestamp, endTimestamp, ctas, ...field }) => ({
    ...field,
    startTimestamp,
    endTimestamp,
    ranges: [msToSeconds(startTimestamp), msToSeconds(endTimestamp)],
    ctas: ctas.map(({ image, button, config, ...cta }) => ({
      ...cta,
      type: image ? CTAItemType.Image : CTAItemType.Button,
      template: image || button,
      styles: image || button,
      width: JSON.parse(config).width,
      config: JSON.parse(config),
    })),
  }));
};

export const percentageToPx = (percentageValue: number, containerSize: number) => {
  return Math.ceil((percentageValue / 100) * containerSize);
};
