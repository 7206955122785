import styled from 'styled-components';
import { th, THUMBNAIL_PREVIEW_BUTTON_Z_INDEX, THUMBNAIL_PREVIEW_Z_INDEX } from '../../styles';
import { IconButton } from '../common/IconButton';

export const OverlayPlayButton = styled(IconButton)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 15px;
  background: ${th('bgSecondary', 0.6)};
  width: 100px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  z-index: ${THUMBNAIL_PREVIEW_BUTTON_Z_INDEX};
`;

export const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: ${THUMBNAIL_PREVIEW_Z_INDEX};
`;
