import styled from 'styled-components';
import { th } from '../../styles/theming/helpers';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 30px;
  margin-right: auto;
`;

export const Title = styled.h1`
  display: block;
  font-size: 22px;
  font-weight: 500;
  margin: 0;
  padding: 0 0 12px;
`;

export const BasicInfo = styled.div`
  display: flex;
  align-items: center;
  padding: 11px 0;
  margin: 15px 0 25px;
  border: solid 1px ${th('outline')};
  border-width: 1px 0;
`;

export const Description = styled.article`
  color: ${th('paragraphFont')};
  font-size: 15px;
  line-height: 25px;
`;

export const Section = styled.section`
  padding-bottom: 40px;
`;

export const Pins = styled.div``;
export const Pin = styled.div``;

export const ParentContainer = styled.div`
  margin-bottom: 15px;
  a {
    text-decoration: none;
    color: ${th('font')};
    &:hover {
      opacity: 0.7;
    }
  }
`;

export const Label = styled.span`
  color: ${th('fontSecondary')};
  padding-right: 5px;
`;

export const ParentTitle = styled.span``;
