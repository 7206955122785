import React from 'react';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';

import * as Styled from './AddedDate.styles';

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo('en-US');

export interface AddedDateProps {
  children: string;
}

export const AddedDate: React.FC<AddedDateProps> = ({ children }) => (
  <Styled.Container>{timeAgo.format(new Date(children))}</Styled.Container>
);
