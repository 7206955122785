import React, {
  createContext,
  RefObject,
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import ReactPlayer from 'react-player';
import type { ReactPlayerProps } from 'react-player';
import screenfull from 'screenfull';
import { LocalStorage } from '../../utils/storage';
import { Nullable } from '../../utils/types';
import { LangCode, HlsPlayer, HlsLevel, VideoItemMoment } from '../../types';
import {
  CaptionOptionsContextProvider,
  generateCaptionTrackId,
} from '../PlayerControls/PlayerCaptionsMenu.utils';
import { noop } from '../../utils/common';
import { useIsControlsVisible } from '../../hooks/useIsControlsVisible';

type PickedPlayerProps = Pick<
  ReactPlayerProps,
  | 'playing'
  | 'volume'
  | 'onProgress'
  | 'onEnded'
  | 'onDuration'
  | 'onPlay'
  | 'onPause'
  | 'onReady'
  | 'onBuffer'
  | 'onBufferEnd'
  | 'onError'
>;
interface PlayerHandlers extends Required<PickedPlayerProps> {}

export type PlayerError = {
  isError: boolean;
  errorMessage?: string;
};

export interface PlayerContextValue {
  seekTo: (seconds: number) => void;
  currentTime: number;
  onCurrentTimeChange: (currentTime: number) => void;
  currentMomentTime: number;
  playedFraction: number;
  loadedTime: number;
  onLoadedTimeChange: (loadedTime: number) => void;
  loadedFraction: number;
  duration: number;
  onDurationChange: (duration: number) => void;
  isPlaying: boolean;
  onPlayingToggle: (isPlaying: boolean) => void;
  volume: number;
  onVolumeChange: (volume: number) => void;
  isMuted: boolean;
  onMuteToggle: (isMuted: boolean) => void;
  isEnded: boolean;
  onEnded: (isEnded: boolean) => void;
  isFullScreen: boolean;
  onFullScreenToggle: () => void;
  isLoading: boolean;
  onLoading: (isLoading: boolean) => void;
  playerError: PlayerError;
  onPlayerError: (isError: PlayerError) => void;
  handlers: PlayerHandlers;
  initialize: (
    playerRefObj: RefObject<ReactPlayer>,
    containerRefObj: RefObject<HTMLElement>,
    playerId: string,
    onViewCount: () => void,
    currentMoment?: VideoItemMoment,
  ) => void;
  playerRef: Nullable<RefObject<ReactPlayer>>;
  isPlayerReady: boolean;
  playerContainerRef: Nullable<HTMLElement>;
  onCaptionsChange: (langCode: Nullable<LangCode>) => void;
  selectedCaptions: Nullable<LangCode>;
  streamLevels: Nullable<HlsLevel[]>;
  selectedLevel: number;
  onLevelChange: (levelIndex: number) => void;
  onKeyPress: (ev: React.KeyboardEvent) => void;
  isControlsVisible: boolean;
}

const VOLUME_CHANGE_STEP = 0.1; // in [0;1] scale
const TIME_CHANGE_STEP = 10; // in seconds
const VIEW_COUNT_MINIMUM_TIME_WATCHED = 30; // in seconds
const VIEW_COUNT_MINIMUM_FRACTION_WATCHED = 0.2; // fraction of video duration

export const PlayerContext = createContext<PlayerContextValue | undefined>(undefined);

export const useCreatePlayerContext = (): PlayerContextValue => {
  const [currentTime, setCurrentTime] = useState(0);
  const [currentMomentTime, setCurrentMomentTime] = useState(0);
  const [loadedTime, setLoadedTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isEnded, setIsEnded] = useState(false);
  const [isPlayerReady, setIsPlayerReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [playerError, setPlayerError] = useState<PlayerError>({ isError: false });
  const [selectedCaptions, setSelectedCaptions] = useState<Nullable<LangCode>>(null);
  const [lastSelectedCaptions, setLastSelectedCaptions] = useState<Nullable<LangCode>>(null);
  const [playerId, setPlayerId] = useState('init');
  const [onViewCount, setOnViewCount] = useState<() => void>(noop);

  const [currentMoment, setCurrentMoment] = useState<Nullable<VideoItemMoment>>(null);
  const [previousTime, setPreviousTime] = useState(0);
  const [watchedTime, setWatchedTime] = useState(0);
  const [isViewCounted, setIsViewCounted] = useState(false);

  const [containerElement, setContainerElement] = useState<Nullable<HTMLElement>>(null);
  const playerRef = useRef<Nullable<ReactPlayer>>(null);

  const initialize = useCallback<PlayerContextValue['initialize']>(
    (playerRefObj, containerRefObj, playerId, onViewCount, currentMoment) => {
      setContainerElement(containerRefObj.current);
      playerRef.current = playerRefObj.current;
      setPlayerId(playerId);
      setOnViewCount(() => onViewCount);
      if (currentMoment) {
        setCurrentMoment(currentMoment);
      }
    },
    [],
  );

  const requiredTimeForView = React.useMemo(
    () =>
      Math.min(
        VIEW_COUNT_MINIMUM_TIME_WATCHED,
        (currentMoment ? currentMoment.endTimestamp - currentMoment.startTimestamp : duration) *
          VIEW_COUNT_MINIMUM_FRACTION_WATCHED,
      ),
    [duration, currentMoment],
  );

  React.useEffect(() => {
    if (!isLoading && isPlaying) {
      setWatchedTime(watchedTime + currentTime - previousTime);
    }
    setPreviousTime(currentTime);
  }, [currentTime, previousTime, isLoading, isPlaying, watchedTime]);

  React.useEffect(() => {
    if (currentMoment && currentTime) {
      setCurrentMomentTime(currentTime - currentMoment.startTimestamp);
    }
  }, [currentTime, currentMoment]);

  React.useEffect(() => {
    if (requiredTimeForView === 0) {
      // Player hasn't been initialised yet
      return;
    }

    if (isViewCounted) {
      return;
    }

    if (watchedTime >= requiredTimeForView) {
      setIsViewCounted(true);
      onViewCount();
    }
  }, [watchedTime, requiredTimeForView, isViewCounted, onViewCount]);

  //If browser stops autoplay, we have to refresh state
  React.useEffect(() => {
    if (isPlayerReady) {
      setIsPlaying(playerRef.current?.player?.isPlaying || false);
    }
  }, [isPlayerReady]);

  const seekTo = useCallback(
    (seconds: number) => {
      playerRef.current?.seekTo(seconds, 'seconds');
    },
    [playerRef],
  );

  const [volume, setVolume] = useState(() => LocalStorage.get('volume', 1));
  const onVolumeChange = useCallback((volume: number) => {
    setVolume(volume);
    LocalStorage.set('volume', volume);
  }, []);

  const [isMuted, setIsMuted] = useState(() => LocalStorage.get('muted', false));
  const onMuteToggle = useCallback((isMuted: boolean) => {
    setIsMuted(isMuted);
    LocalStorage.set('muted', isMuted);
  }, []);

  const [isFullScreen, setIsFullScreen] = useState(false);
  const onFullScreenToggle = useCallback(() => {
    if (screenfull.isEnabled && containerElement) {
      if (isFullScreen) {
        screenfull.exit();
      } else {
        screenfull.request(containerElement);
      }
    }
  }, [containerElement, isFullScreen]);

  const onFullScreenChange = useCallback(() => {
    if (screenfull.isEnabled) {
      setIsFullScreen(screenfull.isFullscreen);
    }
  }, []);

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on('change', onFullScreenChange);
    }
    return () => {
      if (screenfull.isEnabled) {
        screenfull.off('change', onFullScreenChange);
      }
    };
  }, [onFullScreenChange]);

  const playedFraction = duration > 0 ? currentTime / duration : 0;
  const loadedFraction = duration > 0 ? loadedTime / duration : 0;

  //reset player error if when player is ready - if there is an error player won't be ready
  useEffect(() => {
    if (isPlayerReady || isPlaying) {
      setPlayerError({ isError: false });
    }
  }, [isPlayerReady, isPlaying]);

  /* CAPTIONS */
  const hideAllTracks = React.useCallback(() => {
    if (playerRef.current) {
      const player = playerRef.current.getInternalPlayer() as HTMLVideoElement;

      for (let index = 0; index < player.textTracks.length; index++) {
        player.textTracks[index].mode = 'disabled';
      }
    }
  }, [playerRef]);

  React.useEffect(() => {
    if (!selectedCaptions) {
      hideAllTracks();
      return;
    }
    if (playerRef.current) {
      hideAllTracks();
      const player = playerRef.current.getInternalPlayer() as HTMLVideoElement;
      const selectedTrack = player.textTracks.getTrackById(
        generateCaptionTrackId(playerId, selectedCaptions),
      );

      if (selectedTrack) {
        selectedTrack.mode = 'showing';
      }
    }

    return () => {
      hideAllTracks();
    };
  }, [selectedCaptions, hideAllTracks, playerId]);

  React.useEffect(() => {
    if (selectedCaptions) {
      setLastSelectedCaptions(selectedCaptions);
    }
  }, [selectedCaptions]);

  /* STREAM */
  const [hlsPlayer, setHlsPlayer] = React.useState<HlsPlayer>();
  const [streamLevels, setStreamLevels] = React.useState<Nullable<HlsLevel[]>>(null);
  const [selectedLevel, setSelectedLevel] = React.useState(-1);

  React.useEffect(() => {
    if (hlsPlayer) {
      setStreamLevels(hlsPlayer.levels);
      setSelectedLevel(hlsPlayer.currentLevel);
    }
  }, [hlsPlayer]);

  React.useEffect(() => {
    if (hlsPlayer && streamLevels) {
      hlsPlayer.currentLevel = selectedLevel;
    }
  }, [hlsPlayer, selectedLevel, streamLevels]);

  const handlers = usePlayerControls({
    isPlaying,
    volume,
    onCurrentTimeChange: setCurrentTime,
    onPlayingToggle: setIsPlaying,
    onDurationChange: setDuration,
    onEndedCallback: setIsEnded,
    onReadyToggle: setIsPlayerReady,
    onLoadedTimeChange: setLoadedTime,
    onLoading: setIsLoading,
    onPlayerError: setPlayerError,
    setHlsPlayer,
  });

  const togglePlay = React.useCallback(() => {
    setIsPlaying((value) => !value);
  }, [setIsPlaying]);

  const seekForward = React.useCallback(() => {
    seekTo(currentTime + TIME_CHANGE_STEP);
  }, [seekTo, currentTime]);

  const seekBackward = React.useCallback(() => {
    seekTo(currentTime - TIME_CHANGE_STEP);
  }, [seekTo, currentTime]);

  const increaseVolume = React.useCallback(() => {
    setVolume((value) => Math.min(value + VOLUME_CHANGE_STEP, 1));
  }, [setVolume]);

  const decreaseVolume = React.useCallback(() => {
    setVolume((value) => Math.max(value - VOLUME_CHANGE_STEP, 0));
  }, [setVolume]);

  const toggleIsMuted = React.useCallback(() => {
    setIsMuted((value) => !value);
  }, [setIsMuted]);

  const toggleCaptions = React.useCallback(() => {
    if (selectedCaptions) {
      setSelectedCaptions(null);
    } else {
      setSelectedCaptions(lastSelectedCaptions);
    }
  }, [setSelectedCaptions, selectedCaptions, lastSelectedCaptions]);

  const onKeyPress = useKeyboard(containerElement, {
    togglePlay,
    seekForward,
    seekBackward,
    increaseVolume,
    decreaseVolume,
    toggleIsMuted,
    toggleFullscreen: onFullScreenToggle,
    toggleCaptions,
  });

  const { isControlsVisible } = useIsControlsVisible({ containerElement, isPlaying });

  return {
    seekTo,
    currentTime,
    onCurrentTimeChange: setCurrentTime,
    currentMomentTime,
    playedFraction,
    loadedTime,
    onLoadedTimeChange: setLoadedTime,
    loadedFraction,
    duration,
    onDurationChange: setDuration,
    isPlaying,
    onPlayingToggle: setIsPlaying,
    isEnded,
    onEnded: setIsEnded,
    isLoading,
    onLoading: setIsLoading,
    playerError,
    onPlayerError: setPlayerError,
    volume,
    onVolumeChange,
    isMuted,
    onMuteToggle,
    isFullScreen,
    onFullScreenToggle,
    handlers,
    initialize,
    playerRef,
    isPlayerReady,
    playerContainerRef: containerElement,
    onCaptionsChange: setSelectedCaptions,
    selectedCaptions,
    streamLevels,
    selectedLevel,
    onLevelChange: setSelectedLevel,
    onKeyPress,
    isControlsVisible,
  };
};

export const usePlayerContext = () => {
  const value = useContext(PlayerContext);
  if (!value) {
    throw new Error(
      `[PlayerContext] usePlayerContext needs to be called within PlayerContext tree`,
    );
  }
  return value;
};

export const PlayerContextProvider: React.FC = ({ children }) => {
  const playerContext = useCreatePlayerContext();
  return (
    <PlayerContext.Provider value={playerContext}>
      <CaptionOptionsContextProvider>{children}</CaptionOptionsContextProvider>
    </PlayerContext.Provider>
  );
};

interface PlayerControlsProps
  extends Pick<
    PlayerContextValue,
    | 'isPlaying'
    | 'volume'
    | 'onCurrentTimeChange'
    | 'onPlayingToggle'
    | 'onDurationChange'
    | 'onLoadedTimeChange'
    | 'onLoading'
    | 'onPlayerError'
  > {
  onEndedCallback: (isEnded: boolean) => void;
  onReadyToggle: (isReady: boolean) => void;
  setHlsPlayer: React.Dispatch<React.SetStateAction<HlsPlayer | undefined>>;
}

const usePlayerControls = ({
  isPlaying,
  volume,
  onCurrentTimeChange,
  onPlayingToggle,
  onDurationChange,
  onEndedCallback,
  onReadyToggle,
  onLoadedTimeChange,
  onLoading,
  onPlayerError,
  setHlsPlayer,
}: PlayerControlsProps): PlayerHandlers => {
  const onProgress: PlayerHandlers['onProgress'] = React.useCallback(
    ({ playedSeconds, loadedSeconds }) => {
      onCurrentTimeChange(playedSeconds);
      onLoadedTimeChange(loadedSeconds);
    },
    [onCurrentTimeChange, onLoadedTimeChange],
  );

  const onEnded: PlayerHandlers['onEnded'] = React.useCallback(() => {
    onPlayingToggle(false);
    onEndedCallback(true);
    onLoading(false);
  }, [onPlayingToggle, onEndedCallback, onLoading]);
  const onPlay: PlayerHandlers['onPlay'] = React.useCallback(() => {
    onPlayingToggle(true);
    onEndedCallback(false);
    onLoading(false);
  }, [onPlayingToggle, onEndedCallback, onLoading]);
  const onPause: PlayerHandlers['onPause'] = React.useCallback(() => {
    onPlayingToggle(false);
  }, [onPlayingToggle]);
  const onReady: PlayerHandlers['onReady'] = React.useCallback(
    (player) => {
      onReadyToggle(true);
      setHlsPlayer((player.getInternalPlayer('hls') as HlsPlayer) || undefined);
    },
    [onReadyToggle, setHlsPlayer],
  );
  const onBuffer: PlayerHandlers['onBuffer'] = React.useCallback(() => onLoading(true), [
    onLoading,
  ]);
  const onBufferEnd: PlayerHandlers['onBufferEnd'] = React.useCallback(() => onLoading(false), [
    onLoading,
  ]);
  const onError: PlayerHandlers['onError'] = React.useCallback(
    (error, hlsErrorDetails) => {
      //There is no specific errors for mp4 files
      let errorMessage;
      //Message for HLS errors
      if (hlsErrorDetails && hlsErrorDetails.type && hlsErrorDetails.details) {
        errorMessage = `[${hlsErrorDetails.type}]: ${hlsErrorDetails.details}`;
      }
      //Message for DASH errors
      if (error && error.error && error.error.code && error.error.message) {
        errorMessage = `[${error.error.code}]: ${error.error.message}`;
      }
      onPlayerError({ isError: true, errorMessage });
    },
    [onPlayerError],
  );

  return {
    playing: isPlaying,
    volume,
    onProgress,
    onEnded,
    onPlay,
    onPause,
    onDuration: onDurationChange,
    onReady,
    onBuffer,
    onBufferEnd,
    onError,
  };
};

interface KeyboardCallbacks {
  togglePlay: () => void;
  seekForward: () => void;
  seekBackward: () => void;
  increaseVolume: () => void;
  decreaseVolume: () => void;
  toggleIsMuted: () => void;
  toggleFullscreen: () => void;
  toggleCaptions: () => void;
}

const useKeyboard = (
  playerRef: Nullable<HTMLElement>,
  {
    togglePlay,
    seekForward,
    seekBackward,
    increaseVolume,
    decreaseVolume,
    toggleIsMuted,
    toggleFullscreen,
    toggleCaptions,
  }: KeyboardCallbacks,
) => {
  const onKeyPress = React.useCallback(
    (e: React.KeyboardEvent) => {
      switch (e.nativeEvent.code) {
        case 'Space':
          togglePlay();
          break;
        case 'ArrowRight':
          seekForward();
          break;
        case 'ArrowLeft':
          seekBackward();
          break;
        case 'ArrowUp':
          increaseVolume();
          break;
        case 'ArrowDown':
          decreaseVolume();
          break;
        case 'KeyM':
          toggleIsMuted();
          break;
        case 'KeyF':
          toggleFullscreen();
          break;
        case 'KeyC':
          toggleCaptions();
          break;
        default:
          return;
      }

      e.stopPropagation();
      e.preventDefault();
    },
    [
      togglePlay,
      seekForward,
      seekBackward,
      increaseVolume,
      decreaseVolume,
      toggleIsMuted,
      toggleFullscreen,
      toggleCaptions,
    ],
  );

  return onKeyPress;
};
