import styled from 'styled-components';

import { TABLET_QUERY } from '../../styles/breakpoints';
import { th } from '../../styles/theming/helpers';

interface MarginWrapperProps {
  slickListHeight?: number;
}

export const MarginWrapper = styled.div<MarginWrapperProps>`
  margin-bottom: 95px;

  ${TABLET_QUERY} {
    margin-bottom: 50px;
  }

  .slick-list {
    min-height: ${({ slickListHeight }) => (slickListHeight ? `${slickListHeight}px` : 'unset')};
  }

  .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: ${({ slickListHeight }) => (slickListHeight ? `${slickListHeight}px` : 'unset')};
  }
`;

export const VideoWrapper = styled.div`
  height: 100%;

  ${TABLET_QUERY} {
    margin-top: 32px;
  }
`;
// FIXME: https://github.com/akiran/react-slick/issues/1877
export const SliderWrapper = styled.div<{ isReady: boolean }>`
  display: flex;

  .slick-list {
    overflow: hidden;
  }

  .slick-slider {
    width: 100%;
  }

  .slick-slide > div {
    ${({ isReady }) =>
      isReady
        ? `
    width: 100%;
    height: 100%;
    visibility: visible;
  `
        : ``}
  }
`;

export const SliderNav = styled.div`
  border-left: 2px solid ${th('outline')};
  display: flex;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  justify-content: space-between;
  margin-right: 40px;
  width: 36%;
`;

interface NavElementProps {
  inactive: boolean;
}

export const NavElement = styled.div<NavElementProps>`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 33.3333%;
  justify-content: center;
  padding: 0 0 10px 25px;
  position: relative;

  &::before {
    background-color: ${th('primary')};
    border-radius: 2px;
    content: '';
    display: block;
    height: 100%;
    left: -3px;
    opacity: ${({ inactive }) => (inactive ? '0' : '1')};
    position: absolute;
    top: 0;
    transition: all 0.2s;
    width: 4px;
  }
`;

export const RedirectLink = styled.span`
  color: ${th('primary')};
  font-size: 14px;
  font-weight: bold;
  margin-top: 10px;
  padding: 0;

  .next-arrow {
    display: inline-block;
    margin-left: 10px;
  }
`;

export const ShareButtonOutline = styled.div`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: rgba(218, 45, 106, 0.15);
  }
`;

export const MobileTitleWrapper = styled.div`
  position: relative;
`;

export const ShareButtonMobile = styled.span`
  position: absolute;
  right: 8px;
  top: 0;
`;
