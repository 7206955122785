import { Captions } from './Captions';
import { SocialMediaTypes, ObjectSliderItem, VideoMonetization, OverlaysFontFamilyOption } from '.';
import { VideoCTATimestampsResponse } from './VideoTimeStamps';
import { Nullable } from '../utils/types';
import { EndcardOrientationOption } from '../types';

export enum VideoItemType {
  Moment = 'Micro Moment',
  Video = 'Video',
}

export enum IconOption {
  CartPlus = 'CART_PLUS',
  ArrowRight = 'ARROW_RIGHT',
  Play = 'PLAY',
  ShareArrow = 'SHARE_ARROW',
  ShareBg = 'SHARE_BG',
}

export enum VerticalAlignment {
  Top = 'top',
  Center = 'center',
  Bottom = 'bottom',
}

export enum HorizontalAlignment {
  Left = 'left',
  Center = 'center',
  Right = 'right',
}

export enum Direction {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface CTAButtonProps {
  id: string;
  name: string;
  url: string;
  text: string;
  background: string;
  border: string;
  icon: string;
}

export enum EndCardType {
  GUI_BUILDER = 'GUI_BUILDER',
  CUSTOM_JS = 'CUSTOM_JS',
}

export interface EndCardProps {
  id: string;
  callToActionButton?: {
    label: string;
    icon: IconOption;
    url: string;
  };
  promoCodeButton?: {
    label: string;
    text: string;
  };
  watchNextButtonVisible: boolean;
  socialMediaButtons?: SocialMediaTypes[];
  cardSize: string;
  overlay: {
    color: string;
    opacity: string;
  };
  background: {
    color: string;
    opacity: string;
  };
  backgroundImageSettings: {
    size: string;
    top: string;
    left: string;
    horizontal: HorizontalAlignment;
    vertical: VerticalAlignment;
  };
  border: {
    width: string;
    color: string;
    radius: string;
  };
  videoControlButtons: {
    iconsColor: string;
    backgroundColor: string;
    backgroundOpacity: string;
  };
  headline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  subline: {
    text: string;
    font: OverlaysFontFamilyOption;
    fontSize: string;
    color: string;
  };
  backgroundImageUrl: string;
  cardType: string;
  ctaButton1: CTAButtonProps;
  ctaButton2: CTAButtonProps;
  customCode: Nullable<string>;
  origin: EndCardType;
  cardOrientation: EndcardOrientationOption.HORIZONTAL | EndcardOrientationOption.VERTICAL;
}

interface PromotedContentSettingsProps {
  promotedObjectsLabel?: string;
  promoteObjects: boolean;
  promoteAtStart: boolean;
  promoteAtEnd: boolean;
}

interface VideoItemBase {
  id: string;
  title: string;
  description?: string;
  videoFileUrl: string;
  slug: string;
  // `null` is allowed for legacy reasons, new items require thumbnails to be present
  thumbnailUrl: string | null;
  duration?: number;
  videoStreams?: {
    manifestFileUrl: string;
    standard: string;
  }[];
  endCard?: EndCardProps;
  promotedContentSettings?: PromotedContentSettingsProps;
  objects?: ObjectSliderItem[];
  ctaTimestamps: VideoCTATimestampsResponse['ctaTimestamps'];
}

export interface VideoItemVideo extends VideoItemBase {
  type: VideoItemType.Video;
  moments?: VideoItemMoment[];
  captions?: Captions[];
  monetization?: VideoMonetization;
  orgLogoUrl?: string;
  orgLogoEnabled?: boolean;
  orgLogoPosition?: string;
}

export interface VideoItemMoment extends VideoItemBase {
  type: VideoItemType.Moment;
  duration: number;
  startTimestamp: number;
  endTimestamp: number;
  video?: VideoItemVideo;
}

export type VideoItem = VideoItemVideo | VideoItemMoment;
