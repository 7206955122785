export const LAYOUT_WIDTH = 1190;
export const LAYOUT_WIDTH_SMALL = 990;
export const LAYOUT_PADDING = 15;
export const MOBILE = 750;
export const TABLET = 885;

export const LAYOUT_QUERY = `@media (max-width: ${LAYOUT_WIDTH + 100}px)`;
export const MOBILE_QUERY = `@media (max-width: ${MOBILE}px)`;
export const DESKTOP_QUERY = `@media (min-width: ${MOBILE}px)`;
export const TABLET_QUERY = `@media (max-width: ${TABLET}px)`;
export const MIN_TABLET_QUERY = `@media (min-width ${TABLET}px)`;
