import { VideoJsPlayerOptions } from 'video.js';

export const INITIAL_OPTIONS: VideoJsPlayerOptions = {
  autoplay: false,
  controls: true,
  responsive: true,
  fluid: true,
  fill: true,
  playbackRates: [0.5, 1, 1.5, 2],
  playsinline: true,
  preload: 'auto',
  controlBar: {
    chaptersButton: false,
    pictureInPictureToggle: false,
    playbackRateMenuButton: true,
  },
};
