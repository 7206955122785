import React from 'react';

export const noop = () => {};

export function stopPropagation<TEvent extends React.SyntheticEvent>(
  callback: (event: TEvent) => void,
): typeof callback {
  return (event) => {
    event.stopPropagation();
    callback(event);
  };
}

export function isEventTargetOutsideCurrentTarget(event: FocusEvent | React.FocusEvent) {
  return (
    event.currentTarget &&
    event.relatedTarget &&
    !(event.currentTarget as Node).contains(event.relatedTarget as Node)
  );
}
