import React from 'react';
import { VideoItem } from '../../../types';
import * as Styled from './Link.styles';

export interface SearchProps {
  query?: string;
  type?: 'video' | 'moment';
  publicTags?: string[];
  internalTags?: string[];
  orderBy?: string;
  playlistId?: string;
  sectionTitle?: string;
}

export interface LinkItem extends Partial<Pick<VideoItem, 'id' | 'slug' | 'type'>> {
  externalUrl?: string;
  className?: string;
  search?: SearchProps;
}

export type OnLinkClick = (item: LinkItem, event: React.MouseEvent<HTMLAnchorElement>) => void;

//TODO: Docs & 'how to' instructions
/* Helper component that can handle custom linking component like next/link
 * it will render (in that order):
 * - simple anchor for externalUrl
 * - linkComponent if provided
 * - only children if no props provided
 * */
export interface LinkProps extends Omit<React.ComponentPropsWithoutRef<'a'>, 'onClick'> {
  item: LinkItem;
  onClick?: OnLinkClick;
  children: React.ReactNode;
}

export type LinkComponentType = React.FC<LinkProps>;

export interface LinkableProps {
  linkComponent?: React.FC<LinkProps>;
}

export interface LinkControllerProps extends Omit<React.ComponentPropsWithoutRef<'a'>, 'onClick'> {
  item?: LinkItem;
  component?: LinkComponentType;
  onClick?: OnLinkClick;
  children: React.ReactNode;
}
export const LinkController: React.FC<LinkControllerProps> = ({
  onClick,
  component,
  item = {},
  ...linkProps
}) => {
  const LinkComponent = !component || item?.externalUrl ? externalLink : component;
  return <LinkComponent {...linkProps} item={item} onClick={onClick} />;
};

export const externalLink: LinkComponentType = ({ children, item: { externalUrl }, className }) =>
  externalUrl ? (
    <Styled.ExternalLink
      href={externalUrl}
      className={className}
      target="_blank"
      rel="noreferrer noopener"
    >
      {children}
    </Styled.ExternalLink>
  ) : (
    <>{children}</>
  );
