import styled from 'styled-components';
import { th } from '../../styles/theming/helpers';

import variants from '../../helpers/variants';

const variantJustify = variants({
  variantName: 'justify',
  variantsTable: {
    normal: {
      flex: '0 0 auto',
      marginRight: '36',
    },
    stretch: {
      flex: '1 0',
      marginRight: '0',
    },
  },
  defaultVariant: 'normal',
});

const variantSize = variants({
  variantName: 'size',
  variantsTable: {
    normal: {
      fontSize: 16,
    },
    small: {
      fontSize: 14,
    },
  },
  defaultVariant: 'normal',
});

export const Wrapper = styled.div`
  width: 100%;
  position: relative;

  @media (max-width: 500px) {
    &::after {
      position: absolute;
      content: '';
      height: 41px;
      width: 30px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.001) 10%, #fff);
      top: 0;
      right: 0;
    }
  }
`;

export const TabsPanel = styled.div`
  display: flex;
  border-bottom: solid 1px ${th('outline')};
  position: relative;

  @media (max-width: 500px) {
    overflow-x: scroll;
    overflow-y: hidden;

    ::after {
      background: rgba(255, 255, 255, 0.001) 10%;
      content: '';
      display: block;
      flex-basis: 1px;
      flex-shrink: 0;
      height: 1px;
    }
  }
`;

interface TabProps {
  readonly active: boolean;
}

export const Tab = styled.button<TabProps>`
  display: block;
  background: none;
  border: none;
  color: ${(props) => (props.active ? th('font') : th('paragraphFont'))};
  opacity: ${(props) => (props.active ? 1 : 0.6)};
  cursor: pointer;

  font-weight: ${(props) => (props.active ? 700 : 300)};
  padding: 10px ${(props) => (props.active ? 2 : 4)}px;

  font-size: ${variantSize('fontSize')}px;

  flex: ${variantJustify('flex')};
  margin-right: ${variantJustify('marginRight')}px;

  &:first-child {
    padding-left: 2px;
  }
`;

export const ContentItem = styled.div``;

interface ActiveBarProps {
  readonly width: number;
  readonly left: number;
}

export const ActiveBar = styled.div.attrs<ActiveBarProps>((props) => ({
  style: {
    width: props.width,
    transform: `translateX(${props.left}px)`,
  },
}))<ActiveBarProps>`
  position: absolute;
  left: 0;
  bottom: -1px;
  height: 3px;
  background: ${th('primary')};
  border-radius: 20%;
  transition: width ease-out 0.2s, transform linear 0.2s;
`;
