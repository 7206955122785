import styled, { css } from 'styled-components';
import { th, TABLET_QUERY, MOBILE_QUERY, alpha, resetButton } from '../../../styles';

export interface ActionButtonWrapperProps {
  transparent?: boolean;
  hideOnMobile?: boolean;
  buttonColor?: string;
  fontWeight?: string;
  fontSize?: number;
  fontName?: string;
  iconColor?: string;
  showMobileVersion?: boolean;
  iconVersion?: boolean;
  customIconColor?: string;
  buttonBackground?: string;
  buttonBackgroundOpacity?: string;
  iconsColor?: string;
}

const ActionButtonSubtitleMobile = `
  font-size: 11px;
`;

export const ActionButtonSubtitle = styled.span`
  display: block;
  font-size: 12px;
  font-weight: normal;

  ${TABLET_QUERY} {
    ${ActionButtonSubtitleMobile}
  }
`;

const ActionButtonWrapperTablet = `
  font-size: 14px;

`;

const ActionButtonWrapperMobile = `
  font-size: 12px !important;
  padding: 5px;

  :not(:last-child) {
    margin-right: 0;
  }
`;

export const ActionButtonWrapper = styled.button<ActionButtonWrapperProps>`
  // ${resetButton}
  background: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 100%;
  background-color: ${({ buttonBackground, buttonBackgroundOpacity }) =>
    alpha(buttonBackground || '#000000', Number(buttonBackgroundOpacity) || 1)};

  svg {
    margin-right: 0 !important;
    stroke: ${({ iconsColor }) => iconsColor || th('primary')};
    width: 16px;
    height: 16px;
  }

  ${TABLET_QUERY} {
    ${ActionButtonWrapperTablet}
  }

  ${MOBILE_QUERY} {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex;')};
    ${ActionButtonWrapperMobile}
  }

  ${({ showMobileVersion }) =>
    showMobileVersion
      ? css`
          svg {
            max-width: 32px;
          }

          ${ActionButtonWrapperTablet}
          ${ActionButtonWrapperMobile}
        `
      : null}
`;
