import { SupportedIconType } from '.';

export interface CallToActionButtonPauseScreen {
  title: string;
  clickHandler?: () => void;
  actionUrl?: string;
  buttonColor?: string;
  iconName?: SupportedIconType;
  iconColor?: string;
  fontName?: string;
  fontWeight?: string;
  fontSize?: number;
  justifyContent?: string;
}

export enum FontFamily {
  Roboto = 'Roboto',
  RobotoCondensed = 'Roboto+Condensed',
  Sora = 'Sora',
  Oswald = 'Oswald',
  Raleway = 'Raleway',
  SpaceGrotesk = 'Space+Grotesk',
  FiraSans = 'Fira+Sans',
  Montserrat = 'Montserrat',
  Poppins = 'Poppins',
  Nunito = 'Nunito',
  JosefinSans = 'Josefin+Sans',
  Orbitron = 'Orbitron',
  Exo2 = 'Exo+2',
  Arvo = 'Arvo',
  EBGaramond = 'EB+Garamond',
  Lora = 'Lora',
  PlayfairDisplay = 'Playfair+Display',
  Pacifico = 'Pacifico',
  Lobster = 'Lobster',
}

export interface OverlaysFontFamilyOption {
  fontFamily: string;
  fontWeight: FontWeight;
  label: string;
  value: string;
}
export enum FontWeight {
  Regular = 400,
  SemiBold = 600,
  Bold = 700,
  ExtraBold = 800,
  Black = 900,
}

export type Nullable<T> = T | null;

export interface OverlaysCTAButton {
  name: string;
  url: string;
  text: string;
  fontFamily: Nullable<OverlaysFontFamilyOption>;
  fontSize: string;
  fontColor: string;
  hasIcon: boolean;
  iconValue: Nullable<string>;
  iconColor: string;
  backgroundColor: string;
  backgroundOpacity: string;
  borderWidth: string;
  borderColor: string;
  borderRadius: string;
}

export enum EndcardOrientationOption {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}
