import styled from 'styled-components';
import { th, MOBILE_QUERY } from '../../../styles';

export const CloseButton = styled.div`
  position: absolute;
  right: 18px;
  top: 18px;

  ${MOBILE_QUERY} {
    right: 12px;
    top: 14px;
  }
`;

export const Header = styled.div`
  padding: 20px;
  font-size: 16px;
  border-bottom: 1px solid ${th('shadowColor', 0.1)};
`;

export const Content = styled.div`
  padding: 16px;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const CloseTextButton = styled.span`
  margin: 20px;
  padding: 20px;
  cursor: pointer;
  background: ${th('primary')};
  color: ${th('invertFont')};
  border-radius: ${th('imageBorderRadius')};
`;
