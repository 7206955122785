import styled, { css } from 'styled-components';

import { th } from '../../styles/theming/helpers';
import { easeGradient, fadingElem } from '../../styles/helpers';
import { MOBILE_QUERY, DESKTOP_QUERY } from '../../styles/breakpoints';

const bottomGrad = css`
  background: ${easeGradient};
  padding: 50px 20px 10px;

  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
`;

interface visibilityProps {
  isShowed?: boolean;
}

export const Container = styled.div<visibilityProps>`
  display: flex;
  align-items: center;

  color: ${th('invertFont')};

  ${fadingElem(0.5)}

  ${DESKTOP_QUERY} {
    position: static;
    ${bottomGrad};
  }
`;

export const MobileBottomControlsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${MOBILE_QUERY} {
    ${bottomGrad};
  }
`;

export const BasicsContainer = styled.div`
  display: flex;
  align-items: center;

  ${MOBILE_QUERY} {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 150px;
    height: 48px;
    border-radius: 24px;
    background: ${th('bgSecondary', 0.6)};
    justify-content: space-around;
    z-index: 99;
  }
`;

export const TimeDisplay = styled.div`
  font-size: 15px;
  line-height: 1;
  margin-right: 25px;
  white-space: nowrap;
  text-align: right;

  ${MOBILE_QUERY} {
    margin-right: auto;
    text-align: left;
  }
`;

const timeSettings = css`
  font-variant-numeric: tabular-nums;
  letter-spacing: -0.04em;
`;

export const TimeDisplayValue = styled.span`
  ${timeSettings};
`;

export const TimeDisplayDuration = styled.span`
  color: ${th('invertFont', 0.5)};
`;

export const Menu = styled.div<{ width?: string }>`
  overflow: hidden;

  ${({ width }) => (width ? `width: ${width}` : '')};
`;

export const ControlActions = styled.div`
  display: flex;
  align-items: center;

  ${DESKTOP_QUERY} {
    & > * + * {
      margin-left: 15px;
    }
  }
`;

export const RangeWrapper = styled.div`
  width: 5px;
  height: 80px;
  padding: 8px 10px;
  box-sizing: content-box;
  position: relative;
`;

export const Range = styled.input.attrs({ type: 'range' })`
  height: 5px;
  width: 80px;

  position: absolute;
  bottom: 0;
  left: 8px;
  transform: rotate(270deg);
  transform-origin: top left;

  -webkit-appearance: none;
  border-left: solid 1px rgba(0, 0, 0, 0.5);
  background: rgba(255, 255, 255, 0.5);

  &:focus {
    outline-color: ${th('primary', 0.5)};
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 2.7px;
    cursor: pointer;
    background: none;
  }

  &::-webkit-slider-thumb {
    border: none;
    height: 15px;
    width: 7px;
    border-radius: 3px;
    background: ${th('primary')};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -6.15px;
    box-shadow: 1px 0 1px rgba(0, 0, 0, 0.25);
  }

  &::-moz-range-track {
    width: 100%;
    height: 2.7px;
    cursor: pointer;
    background: none;
    border: none;
  }

  &::-moz-range-thumb {
    box-shadow: 0px 0px 1.2px #000, 0px 0px 0px #0d0d0d;
    border: none;
    height: 15px;
    width: 7px;
    border-radius: 3px;
    background: rgba(0, 0, 0, 0.9);
    cursor: pointer;
  }
`;
