import styled, { css } from 'styled-components';
import { th, TABLET_QUERY, MOBILE_QUERY } from '../../../styles';

export interface ActionButtonWrapperProps {
  transparent?: boolean;
  hideOnMobile?: boolean;
  buttonColor?: string;
  fontWeight?: string;
  fontSize?: number;
  fontName?: string;
  iconColor?: string;
  showMobileVersion?: boolean;
  iconVersion?: boolean;
  customIconColor?: string;
}

const ActionButtonSubtitleMobile = `
  font-size: 11px;
`;

export const ActionButtonSubtitle = styled.span`
  display: block;
  font-size: 12px;
  font-weight: normal;

  ${TABLET_QUERY} {
    ${ActionButtonSubtitleMobile}
  }
`;

const ActionButtonWrapperTablet = `
  height: 50px;
  font-size: 14px;

  :not(:last-child) {
    margin-right: 16px;
  }
`;

const ActionButtonWrapperMobile = `
  height: 41px;
  font-size: 12px !important;
  padding: 5px;

  :not(:last-child) {
    margin-right: 0;
  }
`;

export const ActionButtonWrapper = styled.button.withConfig({
  shouldForwardProp(prop) {
    return ![
      'transparent',
      'hideOnMobile',
      'buttonColor',
      'fontWeight',
      'fontSize',
      'fontName',
      'iconColor',
      'showMobileVersion',
      'iconVersion',
      'customIconColor',
    ].includes(prop);
  },
})<ActionButtonWrapperProps>`
  align-items: center;
  background-color: ${({ transparent, buttonColor }) =>
    transparent ? 'transparent' : buttonColor || th('bg')};
  border-radius: ${th('imageBorderRadius')};
  border: ${({ transparent, theme, iconVersion }) =>
    transparent && !iconVersion ? `1px solid ${theme.outline}` : 'none'};
  color: ${({ transparent }) => (transparent ? th('invertFont') : th('fontDarker'))};
  display: flex;
  font-weight: 500;
  height: 56px;
  padding: ${({ iconVersion }) => (iconVersion ? '0 5px' : '0 16px')};
  text-align: left;
  cursor: pointer;
  font-weight: ${(props) => (props.fontWeight ? `${props.fontWeight}` : `500`)};
  font-family: ${(props) =>
    props.fontName ? `'${props.fontName}', 'Montserrat', sans-serif` : `inherit`};
  font-size: ${(props) => (props.fontSize ? `${props.fontSize}px` : `14px`)};

  &:hover a {
    text-decoration: none;
  }

  :not(:last-child) {
    text-decoration: none;
  }

  svg {
    margin-right: 10px;
    color: ${(props) => props.iconColor || props.customIconColor || th('primary')};
    ${MOBILE_QUERY} {
      max-height: 32px;
    }
  }

  use {
    fill: ${(props) => props.iconColor || th('primary')};
  }

  span {
    display: block;
  }

  ${TABLET_QUERY} {
    ${ActionButtonWrapperTablet}
  }

  ${MOBILE_QUERY} {
    display: ${({ hideOnMobile }) => (hideOnMobile ? 'none' : 'flex;')};
    ${ActionButtonWrapperMobile}
  }

  ${({ showMobileVersion }) =>
    showMobileVersion
      ? css`
          svg {
            max-width: 32px;
          }

          ${ActionButtonWrapperTablet}
          ${ActionButtonWrapperMobile}
        `
      : null}
`;
