export const lightTheme = {
  name: 'LightTheme',
  primary: '#da2d6a',
  secondary: '#5c5e7a',
  outline: '#dbdee6',
  font: '#181927',
  fontDarker: '#151618',
  fontSecondary: '#9798aa',
  invertFont: '#fff',
  paragraphFont: '#3d3e52',
  shadowColor: '#000000',
  bg: '#ffffff',
  bgSecondary: '#3b3d4f',
  bgLight: '#f6f6fb',
  bgLightSecondary: '#f1f1f7',
  navy: '#121221',
  greyLink: '#bbbcd0',
  headingFontFamily: 'Cairo, sans-serif',
  headingFontWeight: 'bold',
  videoBorderRadius: '20px',
  gridBorderRadius: '15px',
  imageBorderRadius: '10px',
};

export const blueTheme = {
  ...lightTheme,
  name: 'BlueTheme',
  primary: '#1357AB',
  videoBorderRadius: '0px',
  gridBorderRadius: '0px',
  imageBorderRadius: '0px',
};

export const themes = [lightTheme, blueTheme];
