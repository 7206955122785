import styled, { css } from 'styled-components';
import { MOBILE_QUERY, resetButton, TABLET_QUERY, th } from '../../styles';
import { PAUSE_SCREEN_Z_INDEX } from '../../styles';

interface PauseScreenProps {
  showMobileVersion?: boolean;
  showScrollBar?: boolean;
}

const ButtonsAndSliderWrapperMobile = `
  justify-content: flex-start;
  overflow: hidden;
  overflow-y: scroll;
`;

export const ButtonsAndSliderWrapper = styled.div<PauseScreenProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;

  @media all and (max-width: 480px) {
    ${ButtonsAndSliderWrapperMobile}

    ${({ showScrollBar }) =>
      showScrollBar
        ? css`
            overflow: hidden !important;
          `
        : null}
  }

  ${({ showScrollBar }) =>
    showScrollBar
      ? css`
          overflow: hidden !important;
          justify-content: center !important;
        `
      : null}
`;

const TitleMobile = `
  display: none;
`;

export const Title = styled.span`
  color: ${th('invertFont')};
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.21px;
  line-height: 1.33;

  ${MOBILE_QUERY} {
    ${TitleMobile}
  }
`;

interface ActionsWrapperProps {
  readonly justifyContent?: string;
  removeMarginBottom?: boolean;
}

const ActionsWrapperMobile = `
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0;
  flex-wrap: wrap;

  &:last-of-type {
    margin-bottom: 10px;
  }

  & > * {
    margin: 2px;
  }
`;

export const ActionsWrapper = styled.div<ActionsWrapperProps>`
  align-items: center;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  width: 100%;
  margin-bottom: ${({ removeMarginBottom }) => (removeMarginBottom ? '0' : '35px')};

  & > * {
    margin: 0 10px;
  }

  ${MOBILE_QUERY} {
    ${ActionsWrapperMobile}
  }
`;

const SliderWrapperMobile = `
  display: none;
`;

export const SliderWrapper = styled.div`
  margin: 0 auto;
  padding: 5% 35px 0;
  width: 100%;

  ${TABLET_QUERY} {
    padding: 5% 0 0;
  }

  ${MOBILE_QUERY} {
    ${SliderWrapperMobile}
  }
`;

export const NonSliderContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ArrowBase = styled.button`
  background: ${th('primary')};
  border-radius: 8px;
  border: none;
  color: ${th('invertFont')};
  display: none;
  height: 34px;
  position: absolute;
  top: 30px;
  width: 34px;
  z-index: 5;

  svg {
    bottom: 0;
    height: 12px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 7px;
  }

  &:before {
    display: none;
  }
`;

export const ScreenHeader = styled.div`
  display: flex;
  flex: row nowrap;
  justify-content: space-between;
`;

const ReplayButtonTablet = `
  position: absolute;
  top: 16px;
  right: 6px;
`;

export const ReplayButton = styled.button`
  ${resetButton};
  align-self: flex-start;
  display: flex;
  flex: row nowrap;
  color: ${th('invertFont')};
  transition: opacity 0.15s ease-in;

  span {
    padding-left: 14px;
    padding-right: 14px;
  }

  &:hover {
    opacity: 0.8;
  }

  ${TABLET_QUERY} {
    ${ReplayButtonTablet}
  }
`;

const ContainerMobile = `
  padding: 40px 5px 0 5px;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));
    z-index: 9;
    pointer-events: none;
  }
`;

export const Container = styled.div<PauseScreenProps>`
  background-color: ${th('navy', 0.82)};
  padding: 24px 24px 35px;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: ${PAUSE_SCREEN_Z_INDEX};
  box-sizing: border-box;
  overflow: hidden;

  ${MOBILE_QUERY} {
    ${ContainerMobile}
  }

  ${({ showMobileVersion }) =>
    showMobileVersion
      ? css`
          ${ContainerMobile}

          ${ReplayButton} {
            ${ReplayButtonTablet}
          }

          ${SliderWrapper} {
            ${SliderWrapperMobile}
          }

          ${ActionsWrapper} {
            ${ActionsWrapperMobile}
          }

          ${Title} {
            ${TitleMobile}
          }

          ${ButtonsAndSliderWrapper} {
            ${ButtonsAndSliderWrapperMobile}
          }
        `
      : null}

  ${({ showScrollBar }) =>
    showScrollBar
      ? css`
          &:before {
            display: none;
          }
        `
      : null}
`;

// min-height should be set to 99% because
// social media are stretching container
// and then scrollbar will be always visible
export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  min-height: 99%;
`;
