import styled from 'styled-components';
import { mainOutline, resetButton } from '../../styles';
import { th } from '../../styles/theming/helpers';

export const CheckboxLabel = styled.label`
  white-space: nowrap;
  user-select: none;
`;

export const SettingsList = styled.div`
  padding: 7px 0;
`;

export const SettingsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 30px;
  padding: 8px 15px;
  align-items: center;
`;

export const SettingsRowButton = styled(SettingsRow).attrs(() => ({ as: 'button' }))`
  background-color: transparent;
  border: 0;
  color: inherit;
  text-align: left;
  width: 100%;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus,
  &:hover {
    background-color: ${th('bg', 0.15)};
  }
`;

export const CheckboxToggle = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  width: 30px;
  height: 15px;
  background: ${th('greyLink')};
  display: block;
  border-radius: 15px;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 11px;
    height: 11px;
    background: ${th('bg')};
    border-radius: 11px;
    transition: 0.3s;
  }

  &:active::after {
    width: 30px;
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;

  .checkbox-native-input {
    position: absolute;
    height: 0;
    width: 0;
    left: -9999px;
  }

  .checkbox-native-input:focus + ${CheckboxToggle} {
    ${mainOutline};
  }

  .checkbox-native-input:checked + ${CheckboxToggle} {
    background-color: ${th('primary')};
  }

  .checkbox-native-input:checked + ${CheckboxToggle}::after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;

export const SettingsTitle = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 15px;
  margin-bottom: -1px;
`;

export const SettingsTitleButton = styled(SettingsTitle).attrs(() => ({ as: 'button' }))`
  background-color: transparent;
  border: 0;
  color: inherit;
  text-align: left;
  width: 100%;

  &:not(:disabled) {
    cursor: pointer;
  }

  &:focus,
  &:hover {
    background-color: ${th('bg', 0.15)};
  }
`;

export const SettingsSeparator = styled.hr`
  border-color: ${th('bg', 0.3)};
  margin: 7px 0;
`;

export const MenuItemRowButton = styled.button`
  ${resetButton};
  flex-grow: 1;
  white-space: nowrap;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px 15px;

  &:focus,
  &:hover {
    background-color: ${th('bg', 0.15)};
  }

  .selected-icon {
    margin-left: 30px;
  }
`;

export const MenuItemRow = styled.li`
  display: flex;
`;

export const MenuList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

export const OptionsMenuLink = styled.button`
  ${resetButton};
  text-decoration: underline;
  margin-left: auto;
  font-size: 0.9em;
`;
