import React from 'react';
import copy from 'copy-to-clipboard';
import {
  PromoCodeBox,
  PromoCodeContainer,
  PromoCodeText,
  PromoCodeContent,
} from './PromoCode.styles';

export interface PromoCodeProps {
  code: string;
  text: string;
  onCopy: () => void;
  fontName?: string;
  fontWeight?: string;
  fontSize?: number;
  showMobileVersion?: boolean;
}

export const PromoCode: React.FC<PromoCodeProps> = ({
  code,
  text,
  onCopy,
  fontName,
  fontSize,
  fontWeight,
  showMobileVersion,
}) => {
  const handleCopyClick = () => {
    copy(code);
    onCopy();
  };

  return (
    <PromoCodeBox onClick={handleCopyClick} showMobileVersion={showMobileVersion}>
      <PromoCodeContainer fontName={fontName} fontWeight={fontWeight}>
        <PromoCodeText fontSize={fontSize}>{text}:</PromoCodeText>
        <PromoCodeContent>{code}</PromoCodeContent>
      </PromoCodeContainer>
    </PromoCodeBox>
  );
};
