import styled from 'styled-components';

import { th } from '../../../styles/theming/helpers';

export const Section = styled.section`
  padding-bottom: 40px;
`;

interface DescriptionContentProps {
  readMoreVisible: boolean;
}
export const DescriptionContent = styled(Section)<DescriptionContentProps>`
  p {
    padding-bottom: 20px;
  }
  ${({ readMoreVisible }) => (readMoreVisible ? 'padding-bottom: 0px' : '')}
`;

export const ReadMore = styled.a`
  cursor: pointer;
  color: ${th('primary')};
  font-weight: 500;
`;
