import styled from 'styled-components';
import InnerHTML from 'dangerously-set-html-content';

export const Container = styled(InnerHTML)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
`;
