import React, { useMemo } from 'react';
import truncate from 'lodash.truncate';

import { LinkableProps, LinkController, LinkItem } from '../../Utils/Link';

import * as Styled from './NewCTAButton.styles';
import { noop, stopPropagation } from '../../../utils/common';
import { getAllIconOptions } from '../../../utils/ctaHelpers';
import { FontWeight } from '../../../types';

const TITLE_LIMIT = 35;

export interface ActionButtonProps extends LinkableProps, Styled.ActionButtonWrapperProps {
  link?: LinkItem;
  title: string;
  subtitle?: string;
  iconColor?: string;
  onClick?: () => void;
  fontSize?: string;
  fontFamily?: string;
  fontWeight?: FontWeight;
  buttonColor?: string;
  showMobileVersion?: boolean;
  showButtonLabel?: boolean;
  fontColor?: string;
  backgroundOpacity?: string;
  iconValue?: string | null;
}

interface ActionButtonWrapper extends LinkableProps {
  link?: LinkItem;
}

const ActionButtonContainer: React.FC<ActionButtonWrapper> = ({
  link,
  linkComponent,
  children,
}) => {
  const Wrapper = link ? LinkController : React.Fragment;

  return link ? (
    <Wrapper component={linkComponent} item={link}>
      {children}
    </Wrapper>
  ) : (
    <>{children}</>
  );
};

export const NewCTAButton: React.FC<ActionButtonProps> = ({
  linkComponent,
  link,
  hideOnMobile,
  title,
  subtitle,
  iconColor,
  onClick = noop,
  fontSize,
  fontFamily,
  fontWeight,
  buttonColor,
  showMobileVersion,
  showButtonLabel = true,
  borderWidth,
  borderRadius,
  borderColor,
  backgroundOpacity,
  fontColor,
  iconValue,
}) => {
  const iconOption = useMemo(
    () =>
      getAllIconOptions().find(({ value }) => {
        return value === iconValue;
      }),
    [iconValue],
  );

  const ShapeIcon = iconOption?.ShapeComponent || null;
  const Icon = iconOption?.Component || null;
  return (
    <ActionButtonContainer linkComponent={linkComponent} link={link}>
      <Styled.ActionButtonWrapper
        as={link ? 'span' : undefined}
        onClick={stopPropagation(onClick)}
        fontSize={fontSize}
        fontFamily={fontFamily}
        fontWeight={fontWeight}
        buttonColor={buttonColor}
        iconColor={iconColor}
        aria-label={title}
        showMobileVersion={showMobileVersion}
        borderRadius={borderRadius}
        borderColor={borderColor}
        borderWidth={borderWidth}
        backgroundOpacity={backgroundOpacity}
        fontColor={fontColor}
        iconValue={iconValue}
      >
        {Icon ? (
          <>
            {/* @ts-ignore */}
            <Styled.IconsWrapper color={iconColor}>
              {ShapeIcon ? <ShapeIcon /> : null}
              {/* @ts-ignore */}
              <Styled.IconWrapper color={ShapeIcon ? buttonColor : iconColor}>
                <Icon />
              </Styled.IconWrapper>
            </Styled.IconsWrapper>
          </>
        ) : null}
        {showButtonLabel ? (
          <span>
            {title}
            {subtitle ? (
              <Styled.ActionButtonSubtitle>
                {truncate(subtitle, { length: TITLE_LIMIT })}
              </Styled.ActionButtonSubtitle>
            ) : null}
          </span>
        ) : null}
      </Styled.ActionButtonWrapper>
    </ActionButtonContainer>
  );
};
