import styled from 'styled-components';

import { th } from '../../styles/theming/helpers';

const ACTIVE_HEIGHT = 13;
const HEIGHT = 7;

interface MomentActiveProp {
  active: boolean;
}

interface MomentTickProps extends MomentActiveProp {
  isEnd?: boolean;
}

export const getBorderRadius = ({ active, isEnd }: MomentTickProps): string => {
  if (!active) {
    return '1.5px';
  } else {
    return isEnd ? '0 2px 2px 0' : '2px 0 0 2px';
  }
};

interface MomentProps {
  left: string;
  width: string;
}

export const Moment = styled.div.attrs<MomentProps>(({ left, width }) => ({
  style: {
    left,
    width,
  },
}))<MomentProps>`
  cursor: pointer;
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const MomentTick = styled.div<MomentTickProps>`
  height: ${({ active }) => (active ? ACTIVE_HEIGHT : HEIGHT)}px;
  width: 3px;
  border-radius: ${getBorderRadius};
  background-color: ${th('primary')};
  z-index: 5;
`;

export const MomentRange = styled.div<MomentActiveProp>`
  height: ${HEIGHT}px;
  width: 100%;
  background: ${({ active }) => (active ? th('primary', 0.3) : 'none')};
  border-right: solid 1px ${th('primary')};
`;
