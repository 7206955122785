import styled, { css } from 'styled-components';
import { alpha, MOBILE_QUERY, resetButton, TABLET_QUERY, th } from '../../styles';
import { PAUSE_SCREEN_Z_INDEX } from '../../styles';
import { EndcardOrientationOption } from '../../types';

interface PauseScreenProps {
  showMobileVersion?: boolean;
  showScrollBar?: boolean;
  overlayBgColor?: string;
  overlayBgOpacity?: string;
  cardOrientation?: EndcardOrientationOption.HORIZONTAL | EndcardOrientationOption.VERTICAL;
}

interface ReplayButtonProps {
  backgroundColor?: string;
  iconColor?: string;
  backgroundOpacity?: string;
}

interface ButtonsContainerProps {
  cardBgColor?: string;
  overlayBorderRadius?: string;
  overlayBorderColor?: string;
  overlayBorderWidth?: string;
  cardBgOpacity?: string;
  cardSize?: string;
}

interface OverlayTitleProps {
  overlayTitleFontSize?: string;
  overlayTitleColor?: string;
  overlayTitleFontFamily?: string;
}

interface BackgroundImageProps {
  backgroundUrl: string;
  verticalAlignment?: string;
  horizontalAlignment?: string;
  imageWidth?: string;
}

const ButtonsAndSliderWrapperMobile = `
  justify-content: center;
  align-items: center;
  overflow: hidden;
  overflow-y: scroll;
`;

export const ButtonsAndSliderWrapper = styled.div<PauseScreenProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  @media all and (max-width: 480px) {
    ${ButtonsAndSliderWrapperMobile}

    ${({ showScrollBar }) =>
      showScrollBar
        ? css`
            overflow: hidden !important;
          `
        : null}
  }

  ${({ showScrollBar }) =>
    showScrollBar
      ? css`
          overflow: hidden !important;
          justify-content: center !important;
        `
      : null}
`;

const TitleMobile = `
  display: none;
`;

export const Title = styled.span`
  color: ${th('invertFont')};
  display: block;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.21px;
  line-height: 1.33;
  position: absolute;
  top: 2rem;
  left: 2rem;

  ${MOBILE_QUERY} {
    ${TitleMobile}
  }
`;

interface ActionsWrapperProps {
  readonly justifyContent?: string;
  removeMarginBottom?: boolean;
  cardOrientation?: EndcardOrientationOption.HORIZONTAL | EndcardOrientationOption.VERTICAL;
}

const ActionsWrapperMobile = `
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0;
  flex-wrap: wrap;

  &:last-of-type {
    margin-bottom: 10px;
  }

  & > * {
    margin: 2px;
  }
`;

export const ActionsWrapper = styled.div<ActionsWrapperProps>`
  align-items: center;
  display: flex;
  justify-content: ${(props) => props.justifyContent || 'center'};
  z-index: 1;
  row-gap: 12px;
  column-gap: 20px;
  flex-direction: ${({ cardOrientation }) =>
    cardOrientation === EndcardOrientationOption.HORIZONTAL ? 'row' : 'column'};

  ${MOBILE_QUERY} {
    ${ActionsWrapperMobile}
  }
`;

const SliderWrapperMobile = `
  display: none;
`;

export const SliderWrapper = styled.div`
  margin: 0 auto;
  padding: 5% 35px 0;
  width: 100%;

  ${TABLET_QUERY} {
    padding: 5% 0 0;
  }

  ${MOBILE_QUERY} {
    ${SliderWrapperMobile}
  }
`;

export const NonSliderContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const ArrowBase = styled.button`
  background: ${th('primary')};
  border-radius: 8px;
  border: none;
  color: ${th('invertFont')};
  display: none;
  height: 34px;
  position: absolute;
  top: 30px;
  width: 34px;
  z-index: 5;

  svg {
    bottom: 0;
    height: 12px;
    left: 0;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 7px;
  }

  &:before {
    display: none;
  }
`;

export const ScreenHeader = styled.div`
  display: flex;
  flex: row nowrap;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
`;

const ReplayButtonTablet = `
  font-size: 14px;
`;

export const ReplayButton = styled.button<ReplayButtonProps>`
  ${resetButton};
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: row nowrap;
  color: ${({ iconColor }) => iconColor || th('primary')};
  transition: opacity 0.15s ease-in;
  padding: 0.5rem;
  border-radius: 100%;
  background: ${({ backgroundColor, backgroundOpacity }) =>
    alpha(backgroundColor || '#000000', Number(backgroundOpacity) || 1)};

  span {
    padding-left: 14px;
    padding-right: 14px;
  }

  svg {
    width: 16px;
    height: 16px !important;
  }
  width: 32px;
  height: 32px;
`;

const ContainerMobile = `
  // padding: 40px 5px 0 5px;

  &:before {
    position: absolute;
    content: '';
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(0deg, rgba(0, 0, 0, .7), rgba(0, 0, 0, 0));
    z-index: 9;
    pointer-events: none;
  }
`;

export const Container = styled.div<PauseScreenProps>`
  background: ${({ overlayBgColor, overlayBgOpacity }) =>
    alpha(overlayBgColor || '#000000', Number(overlayBgOpacity) || 0.5)};
  padding: 24px 24px 35px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${PAUSE_SCREEN_Z_INDEX};
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  column-gap: ${({ cardOrientation }) =>
    cardOrientation === EndcardOrientationOption.VERTICAL ? '25px' : '75px'}};
  ${TABLET_QUERY} {
    column-gap: 45px;
  }
  ${MOBILE_QUERY} {
    ${ContainerMobile}
    column-gap: 25px;
  }

  ${({ showMobileVersion }) =>
    showMobileVersion
      ? css`
          ${ContainerMobile}

          ${ReplayButton} {
            ${ReplayButtonTablet}
          }

          ${SliderWrapper} {
            ${SliderWrapperMobile}
          }

          ${ActionsWrapper} {
            ${ActionsWrapperMobile}
          }

          ${Title} {
            ${TitleMobile}
          }

          ${ButtonsAndSliderWrapper} {
            ${ButtonsAndSliderWrapperMobile}
          }
        `
      : null}

  ${({ showScrollBar }) =>
    showScrollBar
      ? css`
          &:before {
            display: none;
          }
        `
      : null}
`;

// min-height should be set to 99% because
// social media are stretching container
// and then scrollbar will be always visible
export const ButtonsContainer = styled.div<ButtonsContainerProps>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  // min-height: 99%;
  height: 100%;
  margin-top: 60px;
  margin-bottom: 60px;
  border-radius: ${({ overlayBorderRadius }) => overlayBorderRadius || '0px'};
  background: ${({ cardBgColor, cardBgOpacity }) =>
    alpha(cardBgColor || '#ffffff', Number(cardBgOpacity) || 1)};
  border-color: ${({ overlayBorderColor }) => overlayBorderColor || 'none'};
  border-width: ${({ overlayBorderWidth }) => overlayBorderWidth || '0px'};
  border-style: solid;
  padding: 20px 40px;
  justify-content: space-around;
  overflow: hidden;
  position: relative;
  width: ${({ cardSize }) => {
    const sizes = {
      fullscreen: '100%',
      xxl: '75%',
      xl: '67%',
      l: '50%',
      m: '33%',
      s: '25%',
    };
    return sizes[cardSize?.toLowerCase() as keyof typeof sizes] || '100%';
  }};
  max-height: 450px;
  ${MOBILE_QUERY} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const OverlayTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  text-align: center;
  z-index: 1;
`;

export const OverlayTitle = styled.div<OverlayTitleProps>`
  font-size: ${({ overlayTitleFontSize }) => overlayTitleFontSize || '24px'};
  font-family: ${({ overlayTitleFontFamily }) => overlayTitleFontFamily || 'sans-serif'};
  color: ${({ overlayTitleColor }) => overlayTitleColor || th('invertFont')};
`;

export const OverlaySubTitle = styled.div<OverlayTitleProps>`
  font-size: ${({ overlayTitleFontSize }) => overlayTitleFontSize || '24px'};
  font-family: ${({ overlayTitleFontFamily }) => overlayTitleFontFamily || 'sans-serif'};
  color: ${({ overlayTitleColor }) => overlayTitleColor || th('invertFont')};
`;

export const BackgroundImage = styled.div<BackgroundImageProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: ${({ backgroundUrl }) => `url('${backgroundUrl}')`};
  background-repeat: no-repeat;
  background-position-x: ${({ horizontalAlignment }) => horizontalAlignment || 'center'};
  background-position-y: ${({ verticalAlignment }) => verticalAlignment || 'center'};
  background-size: ${({ imageWidth }) => imageWidth || '100%'};
`;
