import styled from 'styled-components';
import { MOBILE_QUERY, TABLET_QUERY, th } from '../../../../styles';
import { fadingElem } from '../../../../styles/helpers';

interface visibilityProps {
  isShowed?: boolean;
}

export const PlayerTitleContainer = styled.span<visibilityProps>`
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  padding: 10px 20px;
  max-width: 60%;
  font-size: 22px;
  font-weight: bold;
  letter-spacing: -0.21px;
  line-height: 1.33;
  color: ${th('bg')};
  text-shadow: 0 0 5px ${th('shadowColor', 0.5)};

  ${fadingElem(0.5)}

  ${TABLET_QUERY} {
    font-size: 18px;
  }

  ${MOBILE_QUERY} {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;
