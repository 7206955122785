import styled from 'styled-components';
import { th } from '../../styles';
import { DESKTOP_QUERY, MOBILE_QUERY } from '../../styles/breakpoints';

export const CTA = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  font-size: 14px;
  color: #fff;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  opacity: 0;
  transition: opacity 0.25s ease-out;
  cursor: pointer;

  .cta-arrow-icon {
    margin: 2px 0 0 10px;
    color: #c93f6a;
  }
`;

export const Slide = styled.div`
  position: relative;
  border-radius: 7px;
  transition: all 0.15s ease-in;
  width: 100%;
  background-color: #252525;
  color: #fff;

  &:hover ${CTA} {
    opacity: 1;
  }
`;

export const SlideImage = styled.img`
  border-radius: 7px;
  width: 100%;
  height: 65px;
  object-fit: cover;

  @media all and (min-width: 450px) {
    height: 100px;
  }
`;

export const SlideTitle = styled.span`
  color: ${th('invertFont')};
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  padding: 10px 8px;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.3px;
  overflow: hidden;
  text-overflow: ellipsis;

  ${MOBILE_QUERY} {
    padding: 5px 8px;
    line-height: 16px;
  }

  ${DESKTOP_QUERY} {
    padding: 8px 10px;
  }
`;

export const Button = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(37, 37, 37, 0.5);
  border-radius: 7px;
  padding: 10px;
  color: #fff;
  font-size: 14px;
  line-height: 21px;
  border: 0;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  z-index: 1;

  .go-arrow-icon {
    transform: rotate(90deg);
    margin-left: 5px;
  }
`;

export const ProductsTitle = styled.div`
  color: #fff;
  font-size: 12px;
  line-height: 21px;
  color: #fff;
`;

export const ProductsClose = styled.button`
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: 0;
  color: #fff;
  cursor: pointer;
`;

export const Products = styled.div`
  background: rgba(37, 37, 37, 0.7);
  border-radius: 7px;
  position: absolute;
  top: 15px;
  right: 15px;
  width: 100%;
  max-width: 250px;
  overflow: hidden;
  max-height: calc(100% - 70px);
  overflow-y: scroll;
  z-index: 1;

  &::-webkit-scrollbar {
    width: 7px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(37, 37, 37, 0.7);
  }

  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.5);
    border-radius: 10px;
  }

  ${MOBILE_QUERY} {
    top: 0;
    right: 5px;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    left: 0;
    overflow: hidden;
  }

  .slick-track {
    display: flex;
    flex-direction: column;

    ${MOBILE_QUERY} {
      flex-direction: row;
    }
  }
`;

export const ProductsHead = styled.div`
  display: flex;
  position: relative;
  z-index: 9;
  padding: 10px 15px;
`;

export const SliderWrapper = styled.div`
  position: relative;
  padding: 0 15px;
  overflow: hidden;
`;

const SlidesNavigation = `
  position: absolute;
  background-color: #C93F6A;
  top: 50%;
  border: 0;
  border-radius: 10px;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
  transform: translateY(0) translateX(-50%);
  left: 50%;
  right: auto;

  ${MOBILE_QUERY} {
    left: auto;
    top: 50%;
    transform: translateY(-50%);
  }

  .next-slide-icon,
  .prev-slide-icon {
    color: #fff;
    min-width: 6px;
  }

  &.slick-disabled {
    display: none;
  }
`;

export const NextSlide = styled.button`
  ${SlidesNavigation};
  bottom: 20px;
  top: auto;

  ${MOBILE_QUERY} {
    right: 5px;
  }

  .next-slide-icon {
    transform: rotate(90deg);

    ${MOBILE_QUERY} {
      transform: rotate(0);
    }
  }
`;

export const PrevSlide = styled.button`
  ${SlidesNavigation};
  top: 10px;
  bottom: auto;

  ${MOBILE_QUERY} {
    left: 5px;
  }

  .prev-slide-icon {
    transform: rotate(-90deg);

    ${MOBILE_QUERY} {
      transform: rotate(-180deg);
    }
  }
`;

export const SlideWrapper = styled.div`
  padding-bottom: 10px;

  ${MOBILE_QUERY} {
    margin-right: 10px;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
`;
