import { SupportedIconType } from '.';

export enum SocialMediaPlatforms {
  FACEBOOK = 'FACEBOOK',
  TWITTER = 'TWITTER',
  YOUTUBE = 'YOUTUBE',
  TIKTOK = 'TIKTOK',
  INSTAGRAM = 'INSTAGRAM',
}

export interface SocialMediaTypes {
  id: string;
  label: string;
  platformName: SocialMediaPlatforms;
  accountUrl: string;
}

export const socialIconsMap: Record<SocialMediaPlatforms, SupportedIconType> = {
  [SocialMediaPlatforms.FACEBOOK]: 'Facebook',
  [SocialMediaPlatforms.TWITTER]: 'Twitter',
  [SocialMediaPlatforms.YOUTUBE]: 'YouTube',
  [SocialMediaPlatforms.INSTAGRAM]: 'Instagram',
  [SocialMediaPlatforms.TIKTOK]: 'TikTok',
};
