import styled, { css } from 'styled-components';

import { fadingElem } from '../../../styles/helpers';
import { th } from '../../../styles/theming/helpers';

import variants from '../../../helpers/variants';

export enum VARIANTS {
  mini = 'mini',
  normal = 'normal',
}

const variantSize = variants({
  variantName: 'size',
  variantsTable: {
    mini: {
      fontSize: 8,
      titleFontSize: 13,
      titleSmallFontSize: 9,
      titleTopSpace: '0%',
    },
    normal: {
      fontSize: 10,
      titleFontSize: 16,
      titleSmallFontSize: 13,
      titleTopSpace: '0%',
    },
  },
  defaultVariant: 'normal',
});

export const Title = styled.div`
  z-index: 1;
`;

export const OutsideTitle = styled(Title)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  backface-visibility: hidden;
  position: relative;
  & p {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    white-space: normal;
    text-align: left;
    min-height: 45px;
  }
`;
export const InnerTitle = styled(Title)`
  line-height: 1.5;
  font-weight: bold;
  text-align: left;
`;

interface SliderImageProps {
  isMouseOver: boolean;
  offset: number;
  zoomFactor: number;
}

export const SliderImage = styled.div<SliderImageProps>`
  position: relative;
  overflow: hidden;
  max-height: 75%;
  transform: scale(${({ isMouseOver, zoomFactor }) => (isMouseOver ? zoomFactor : 1)})
    translateX(${({ offset }) => offset}px) translateZ(0);
  z-index: ${({ isMouseOver }) => (isMouseOver ? 2 : 1)};

  backface-visibility: hidden;

  transition: transform 0.2s linear;
  box-shadow: ${({ isMouseOver }) => isMouseOver && css`0 .5em 1em ${th('shadowColor', 0.45)}`};

  img {
    display: block;
    width: 100%;
    min-height: 100%;
    height: 150px;
    object-fit: cover;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 2px 2px 8px;

  background: ${th('bgSecondary', 0.75)};
  color: ${th('invertFont')};
  font-size: 10px;
  font-weight: 200;

  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const ProgressBar = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 6.5% 7%;
`;

export const Wrapper = styled.div`
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding-top: 30px;
`;

export const ContainerInner = styled.div`
  padding: 5%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

interface ContainerProps {
  isActive: boolean;
  size: VARIANTS;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  position: relative;

  border-radius: ${th('imageBorderRadius')};
  background: ${({ isActive }) => (isActive ? th('outline') : 'none')};
  font-weight: ${({ isActive }) => (isActive ? 700 : 'normal')};

  text-decoration: none;
  color: ${th('font')};

  ${SliderImage} {
    border-radius: ${th('imageBorderRadius')};
  }

  ${Wrapper},
  ${OutsideTitle} {
    margin: 0 5%;
  }

  ${Wrapper} {
    margin-top: 5%;
  }

  ${Title} {
    font-size: ${variantSize('titleFontSize')}px;
  }

  ${OutsideTitle} {
    padding-top: ${variantSize('titleTopSpace')};
    padding-bottom: 5%;
  }

  ${InnerTitle} {
    font-size: ${variantSize('titleSmallFontSize')}px;
  }
`;

export const HoverOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  padding: 7% 8%;

  max-width: 100%;
  display: flex;

  ${fadingElem(0.2)}
  background: rgba(0, 0, 0, 0.4);
  backface-visibility: hidden;

  color: ${th('invertFont')};
`;
