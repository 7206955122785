import styled, { css } from 'styled-components';
import { th } from '../../styles';

import { MOBILE_QUERY } from '../../styles/breakpoints';
import { CaptionOptions } from '../PlayerControls/PlayerCaptionsMenu.utils';

interface WrapperProps {
  isReady: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  width: 100%;
  height: 100%;
  min-height: 100px;

  opacity: ${(props) => (props.isReady ? 1 : 0)};
  transition: opacity 1s ease;
`;

interface ContainerProps {
  isFullScreen: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  height: 100%;
  display: ${(props) => (props.isFullScreen ? 'flex' : 'inline-block')};
  position: relative;
  ${(props) => (props.isFullScreen ? 'align-items: center;' : '')};
`;

export const ContainerPins = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

interface ContainerRadiusProps {
  isFullScreen: boolean;
  isAlone: boolean;
  captionOptions: CaptionOptions;
}

export const ContainerRadius = styled.div<ContainerRadiusProps>`
  display: block;
  border-radius: ${({ isFullScreen }) => (isFullScreen ? 0 : th('videoBorderRadius'))};
  position: relative;
  overflow: hidden;

  /* to make overflow-hidden with border-radius work on Safari */
  -webkit-mask-image: -webkit-radial-gradient(white, black);

  width: 100%;
  height: 100%;

  ${MOBILE_QUERY} {
    border-radius: ${(props) => (props.isAlone ? 0 : 10)}px;
  }

  .react-player {
    /* fix implicit padding that breaks rounded corners */
    font-size: 0;

    /* fix for Safarai cutting off captions in carousel*/
    video::-webkit-media-text-track-display {
      position: relative;
      top: 80%;
    }

    video::cue {
      ${({ captionOptions }) => css`
        color: ${captionOptions.fontColor};
        font-size: ${captionOptions.fontSize};
      `}
    }
  }
`;

export const OverlayImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
