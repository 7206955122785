import styled from 'styled-components';
import { th } from '../../../styles';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;

  position: relative;
  select {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;

    &:hover + label,
    &:focus + label {
      border-color: ${th('primary')};
    }
  }
`;

export const SelectInput = styled.select`
  font-size: 16px;
`;

export const SelectTitle = styled.span`
  font-size: 14px;
  line-height: 18px;
  margin-bottom: 10px;
`;

export const SelectLabel = styled.label`
  font-size: 14px;
  padding: 20px;
  border: 1px solid #e0e1e8;
  border-radius: ${th('imageBorderRadius')};
  margin-bottom: 20px;
`;
