import styled from 'styled-components';

import { th } from '../../styles/theming/helpers';

export const Wrapper = styled.button`
  align-items: center;
  background: transparent;
  border: 0;
  color: ${th('fontDarker')};
  cursor: pointer;
  display: flex;
  font-size: 15px;
  font-weight: bold;
  outline: none;
  padding: 0;

  :hover,
  :active {
    .next-arrow-wrapper {
      background-color: ${th('primary')};
      border: 1px solid ${th('primary')};
      color: ${th('bg')};
      transition: all 0.3s ease-out;
    }
  }
`;

export const ArrowWrapper = styled.div`
  background-color: ${th('bg')};
  border-radius: 50%;
  border: 1px solid ${th('outline')};
  color: ${th('primary')};
  height: 48px;
  margin-left: 16px;
  position: relative;
  transition: all 0.3s ease-out;
  width: 48px;

  .next-arrow {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
