import React from 'react';
import * as Styled from './CustomCodePauseScreen.styles';

export interface CustomCodePauseScreenProps {
  htmlContent: string;
  isVisible: boolean;
}

export const CustomCodePauseScreen = ({ htmlContent, isVisible }: CustomCodePauseScreenProps) => {
  return isVisible ? <Styled.Container html={htmlContent} /> : null;
};
