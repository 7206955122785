import styled from 'styled-components';
import { th } from '../../../styles';

export const Slide = styled.span`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  border-radius: 15px;
  border: 1px solid transparent;
  padding: 8px;
  transition: all 0.15s ease-in;

  width: 100%;
  max-width: 160px;

  :hover {
    background-color: ${th('bg', 0.1)};
    border: solid 1px ${th('bg', 0.2)};
  }
`;

export const SlideImage = styled.img`
  border-radius: 12px;
  width: 100%;
  max-height: 110px;
  object-fit: cover;
  flex-grow: 1;
`;

export const SlideTitle = styled.span`
  color: ${th('invertFont')};
  display: block;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 12px;
`;
