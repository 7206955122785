import styled from 'styled-components';

import { th } from '../../../styles/theming/helpers';

const ButtonBase = styled.button`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
`;

const HighlightBase = styled.div`
  align-items: center;
  background-color: ${th('primary', 0.25)};
  border-radius: 50%;
  box-shadow: none;
  cursor: pointer;
  display: flex;
  height: 68px;
  justify-content: center;
  transition: all 0.3s ease-in;
  width: 68px;
`;

export const HighlightDefault = styled(HighlightBase)`
  background-color: transparent;

  :hover {
    background-color: ${th('primary', 0.25)};
  }
`;

export const HighlightBlurred = styled(HighlightBase)`
  :hover {
    box-shadow: 0 2px 38px 1px ${th('primary', 0.35)};
  }
`;

export const IconButton = styled(ButtonBase)`
  background-color: ${th('primary')};
  border-radius: 50%;
  color: ${th('invertFont')};
  height: 48px;
  width: 48px;
`;

export const SimpleButton = styled(ButtonBase)`
  background-color: transparent;
  border-radius: 50%;
  color: ${th('primary')};
  height: 32px;
  transition: all 0.3s ease-in;
  width: 32px;

  svg {
    min-width: 16px;
  }

  :hover {
    background-color: ${th('primary')};
    color: ${th('invertFont')};
  }
`;

export const VideoButton = styled(ButtonBase)`
  background-color: ${th('font', 0.35)};
  border-radius: 8px;
  color: ${th('invertFont')};
  height: 28px;
  width: 28px;

  svg {
    min-width: 16px;
  }
`;

export const CarouselShare = styled(IconButton)`
  height: 45px;
  width: 45px;
  border-radius: 50%;
  position: absolute;
  right: 0;
  bottom: -4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${th('primary')};
  background-color: transparent;

  svg {
    overflow: visible;
  }

  &:hover {
    background-color: ${th('primary', 0.25)};
  }
`;
