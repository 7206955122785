import { css } from 'styled-components';
import { Nullable } from '../../utils/types';

export const alpha = (hex: string, alpha: number = 1): Nullable<string> => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  const rgb = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;

  return rgb && `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
};

export const th = (name: string, a?: Nullable<number>) =>
  css`
    ${(props) => (a ? alpha(props.theme[name], a) : props.theme[name])}
  `;
