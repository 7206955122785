import styled from 'styled-components';

import { th } from '../../styles/theming/helpers';
import { TABLET_QUERY } from '../../styles/breakpoints';

export const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  ${TABLET_QUERY} {
    margin-bottom: 40px;
  }
`;

export const GridWrapper = styled.div`
  align-items: start;
  display: grid;
  grid-column-gap: 30px;
  grid-row-gap: 60px;
  grid-template-columns: repeat(4, 1fr);
  margin-bottom: 88px;

  ${TABLET_QUERY} {
    grid-column-gap: 15px;
    grid-row-gap: 40px;
    grid-template-columns: repeat(2, 1fr);
    margin-bottom: 48px;
  }
`;

export const VideoWrapper = styled.div`
  cursor: pointer;
  position: relative;

  &:hover {
    opacity: 0.75;
  }
`;

export const Heading = styled.h1`
  color: ${th('fontDarker')};
  font-family: ${th('headingFontFamily')};
  font-weight: ${th('headingFontWeight')};
  font-size: 30px;
  margin-right: auto;

  ${TABLET_QUERY} {
    font-size: 24px;
  }
`;

interface ThumbnailProps {
  readonly url?: string;
}

export const Thumbnail = styled.div.attrs<ThumbnailProps>(({ url }) => ({
  style: url ? { backgroundImage: `url(${url})` } : {},
}))<ThumbnailProps>`
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${th('gridBorderRadius')};
  height: 0;
  overflow: hidden;
  padding-top: 56%;
  position: relative;
  width: 100%;
`;

export const SeeMoreMobileWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
`;

export const ShareMobileWrapper = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  display: none;

  ${TABLET_QUERY} {
    display: block;
  }
`;

export const ShareButtonWrapper = styled.div<{ isVisible: boolean }>`
  padding: 10px 0 0 10px;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};

  ${TABLET_QUERY} {
    display: none;
  }
`;
