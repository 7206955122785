import React from 'react';
import Tippy, { TippyProps } from '@tippyjs/react';
import type { Plugin } from 'tippy.js';

const hideOnPopperBlur: Plugin = {
  name: 'hideOnPopperBlur',
  defaultValue: true,
  fn(instance) {
    return {
      onCreate() {
        instance.popper.addEventListener('focusout', (event) => {
          if (
            // @ts-ignore: we are attaching this prop via this plugin so it's not available in TippyProps definition
            instance.props.hideOnPopperBlur &&
            event.relatedTarget &&
            !instance.popper.contains(event.relatedTarget as Node)
          ) {
            instance.hide();
          }
        });
      },
    };
  },
};

export interface TooltipProps extends TippyProps {
  hideOnPopperBlur?: boolean;
}

export const Tooltip: React.VFC<TooltipProps> = (props) => {
  return <Tippy {...props} plugins={[hideOnPopperBlur]} />;
};
