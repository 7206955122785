import styled, { css } from 'styled-components';

import {
  MOBILE_QUERY,
  LAYOUT_WIDTH_SMALL,
  LAYOUT_WIDTH,
  LAYOUT_PADDING,
} from '../../styles/breakpoints';

const getWidth = ({ size }: LayoutContainerProps) => {
  switch (size) {
    case 'small':
      return LAYOUT_WIDTH_SMALL;
    default:
      return LAYOUT_WIDTH;
  }
};

export interface LayoutContainerProps {
  size?: string;
  isFlex?: boolean;
  isMobileFull?: boolean;
}

const flexSnip = css`
  display: flex;
  align-items: center;
`;

const fullSnip = css`
  max-width: none;
  padding-left: 0;
  padding-right: 0;
`;

export const LayoutContainer = styled.div<LayoutContainerProps>`
  width: 100%;
  max-width: ${getWidth}px;
  margin: 0 auto;
  padding: 0 ${LAYOUT_PADDING}px;

  ${(props) => (props.isFlex ? flexSnip : '')}

  ${MOBILE_QUERY} {
    ${(props) => (props.isMobileFull ? fullSnip : '')}
  }
`;
