import React, { useEffect, useState } from 'react';

import { Captions, PlayerControlsEvents, PlayerControlsEventsHandler } from '../../types';
import { secondsToFormat } from '../../helpers/time';
import { IconButton } from '../common/IconButton';

import {
  Volume,
  VolumeMuted,
  Settings,
  FullscreenExit,
  FullscreenRequest,
  Pause,
  Play,
  CaptionsIcon,
} from '../../assets/icons';

import { useDevice } from '../../hooks/useDevice';

import { usePlayerContext } from '../Player/PlayerContext';

import * as Styled from './PlayerControls.styles';
import { PlayerVolumeMenu } from './PlayerVolumeMenu';
import { PlayerMenu } from './PlayerMenu';
import { ProgressBar } from './ProgressBar';
import { PlayerSettingsMenu } from './PlayerSettingsMenu';
import { PlayerCaptionsMenu } from './PlayerCaptionsMenu';
import { Modal } from '../common/Modal';
import { MobileMenuCaptions, MobileMenuSettings } from './mobileMenu';
import { noop } from '../../utils/common';
import { LocalStorage } from '../../utils/storage';
import { PlayerControlsMoment } from './PlayerControls.types';

interface PlayerControlsProps {
  moments?: PlayerControlsMoment[];
  activeMomentId?: string | null;
  videoCaptions?: Captions[];
  //TODO: after adding it on O&O create enum for available types
  onControlClick?: PlayerControlsEventsHandler;
  isDisabled?: boolean;
}

const CAPTIONS_STORAGE_KEY = 'SEEEN_SELECTED_CAPTIONS';

enum MobileMenu {
  settings = 'settings',
  captions = 'captions',
}

// MacOS doesn't focus button elements on click.
// This is system settings, and Safari/Firefox obey.
// It has to be added manually.
// see: https://bugs.webkit.org/show_bug.cgi?id=22261
const onClickFocus: React.MouseEventHandler<HTMLButtonElement> = (e) => {
  const target = e.target as HTMLButtonElement;
  target.focus();
};

export const PlayerControls: React.FC<PlayerControlsProps> = ({
  moments,
  activeMomentId,
  videoCaptions,
  onControlClick = noop,
  isDisabled = false,
}) => {
  const { isMobile } = useDevice();
  const {
    currentTime,
    duration,
    isPlaying,
    onPlayingToggle,
    volume,
    onVolumeChange,
    isMuted,
    onMuteToggle,
    isFullScreen,
    onFullScreenToggle,
    selectedCaptions,
    onCaptionsChange,
    streamLevels,
    isControlsVisible,
  } = usePlayerContext();

  const [selectedCaptionsCode, setSelectedCaptionsCode] = useState(
    LocalStorage.get(CAPTIONS_STORAGE_KEY) || selectedCaptions,
  );

  useEffect(() => {
    onCaptionsChange(selectedCaptionsCode);
    LocalStorage.set(CAPTIONS_STORAGE_KEY, selectedCaptionsCode);
  }, [onCaptionsChange, selectedCaptionsCode]);

  const progressBar = (
    <ProgressBar
      moments={moments}
      activeMomentId={activeMomentId}
      onControlClick={onControlClick}
    />
  );

  const [mobileSettings, setMobileSettings] = React.useState<MobileMenu>();

  if (isDisabled) {
    return null;
  }

  const handleCaptionChange = (langCode: string | null) => {
    setSelectedCaptionsCode(langCode);
  };

  return (
    <>
      <Styled.Container isShowed={isControlsVisible || !!mobileSettings} aria-hidden={isDisabled}>
        <Styled.BasicsContainer>
          <IconButton
            title="Toggle play"
            onClick={(event) => {
              event.stopPropagation();
              onControlClick(PlayerControlsEvents.play, isPlaying ? 'stopClick' : 'playClick');
              onPlayingToggle(!isPlaying);
            }}
            isSpaced
            disabled={isDisabled}
          >
            {isPlaying ? <Pause /> : <Play />}
          </IconButton>
        </Styled.BasicsContainer>

        {!isMobile && progressBar}
        <Styled.MobileBottomControlsWrapper>
          <Styled.TimeDisplay aria-current="time">
            <Styled.TimeDisplayValue>
              {!isNaN(currentTime) ? secondsToFormat(currentTime || 0) : '--'}
            </Styled.TimeDisplayValue>
            <Styled.TimeDisplayDuration>
              {' '}
              /{' '}
              <Styled.TimeDisplayValue>
                {!isNaN(duration) ? secondsToFormat(duration || 0) : '--'}
              </Styled.TimeDisplayValue>
            </Styled.TimeDisplayDuration>
          </Styled.TimeDisplay>
          {isMobile && progressBar}
          <Styled.ControlActions>
            {videoCaptions &&
              videoCaptions.length > 0 &&
              (!isMobile ? (
                <PlayerMenu
                  menu={
                    <PlayerCaptionsMenu
                      captions={videoCaptions.sort((a, b) => (a.language > b.language ? 1 : -1))}
                      onChange={handleCaptionChange}
                      onControlClick={onControlClick}
                      selectedLang={selectedCaptionsCode ? selectedCaptionsCode : null}
                    />
                  }
                >
                  <IconButton title="Captions" onClick={onClickFocus} disabled={isDisabled}>
                    <CaptionsIcon />
                  </IconButton>
                </PlayerMenu>
              ) : (
                <IconButton
                  title="Captions"
                  onClick={() => {
                    onControlClick(PlayerControlsEvents.mobileCaptions);
                    setMobileSettings(MobileMenu.captions);
                  }}
                  disabled={isDisabled}
                >
                  <CaptionsIcon />
                </IconButton>
              ))}
            {!isMobile && (
              <PlayerMenu
                trigger="mouseenter focus"
                menu={
                  <PlayerVolumeMenu
                    volume={isMuted ? 0 : volume}
                    onChange={(newVolume) => {
                      onControlClick(PlayerControlsEvents.volumeChange, `${newVolume}`);
                      onVolumeChange(newVolume);
                      onMuteToggle(false);
                    }}
                  />
                }
              >
                <IconButton
                  title="Change volume"
                  onKeyDown={(event) => {
                    // Prevent keyboard shortcuts when control panel element is focused
                    event.stopPropagation();
                  }}
                  onClick={() => {
                    onControlClick(
                      PlayerControlsEvents.mute,
                      isMuted ? 'unmuteClick' : 'muteClick',
                    );
                    onMuteToggle(!isMuted);
                  }}
                  disabled={isDisabled}
                >
                  {isMuted ? <VolumeMuted /> : <Volume />}
                </IconButton>
              </PlayerMenu>
            )}
            {streamLevels ? (
              !isMobile ? (
                <PlayerMenu menu={<PlayerSettingsMenu onControlClick={onControlClick} />}>
                  <IconButton title="Change settings" onClick={onClickFocus} disabled={isDisabled}>
                    <Settings />
                  </IconButton>
                </PlayerMenu>
              ) : (
                <IconButton
                  title="Change settings"
                  onClick={() => {
                    onControlClick(PlayerControlsEvents.mobileSettings);
                    setMobileSettings(MobileMenu.settings);
                  }}
                  disabled={isDisabled}
                >
                  <Settings />
                </IconButton>
              )
            ) : null}
            <IconButton
              title="Toggle fullscreen"
              onKeyDown={(event) => {
                // Prevent keyboard shortcuts when control panel element is focused
                event.stopPropagation();
              }}
              onClick={() => {
                onControlClick(
                  PlayerControlsEvents.fullscreen,
                  isFullScreen ? 'exitFullscreenClick' : 'enterFullscreenClick',
                );
                onFullScreenToggle();
              }}
              disabled={isDisabled}
            >
              {isFullScreen ? <FullscreenExit /> : <FullscreenRequest />}
            </IconButton>
          </Styled.ControlActions>
        </Styled.MobileBottomControlsWrapper>
      </Styled.Container>
      {isMobile && mobileSettings && (
        <Modal
          modalVisible={!!mobileSettings}
          onClose={() => setMobileSettings(undefined)}
          title="Playback Settings"
          fullWidth
          closeButtonText="Ok"
        >
          {mobileSettings === MobileMenu.settings ? (
            <MobileMenuSettings onControlClick={onControlClick} />
          ) : (
            videoCaptions && (
              <MobileMenuCaptions captions={videoCaptions} onControlClick={onControlClick} />
            )
          )}
        </Modal>
      )}
    </>
  );
};
