import React from 'react';
import {
  ShareModal,
  ShareModalProps,
  ShareMomentItem,
  ShareVideoItem,
  ShareItem,
} from './ShareModal';

import {
  ShareEvents,
  ShareEventsHandler,
  VideoItemMoment,
  VideoItemType,
  VideoItemVideo,
} from '../../types';
import { Nullable } from '../../utils/types';
import { noop } from '../../utils/common';

const MISSING_ORIGIN_ERROR = `[ShareModalContext] ShareModalContext requires either site origin or url property in passing item`;

type ShowModalFn<
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
> = (
  videoItem: TVideo | ShareVideoItem<TVideo>,
  momentItem?: TMoment | ShareMomentItem<TMoment>,
) => void;

export interface ShareModalContextValue<
  TVideo extends ShareItem = ShareItem,
  TMoment extends ShareItem = ShareItem
> extends ShareModalProps<TVideo, TMoment> {
  isReady: boolean;
  setModalVisibility: (state: boolean) => void;
  showModal: ShowModalFn<TVideo, TMoment>;
  analyticsHandler: ShareEventsHandler;
}

export const ShareModalContext = React.createContext<ShareModalContextValue | undefined>(undefined);

export function useShareModal<
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
>() {
  const value = React.useContext(ShareModalContext);
  if (!value) {
    return {
      isReady: false,
    } as ShareModalContextValue<TVideo, TMoment>;
  }
  return value as ShareModalContextValue<TVideo, TMoment>;
}

export function useCreateShareModalContext<
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
>(origin: string, analyticsHandler: ShareEventsHandler): ShareModalContextValue<TVideo, TMoment> {
  const [video, setVideo] = React.useState<Nullable<TVideo & { url: string }>>(null);
  const [moment, setMoment] = React.useState<Nullable<TMoment & { url: string }>>(null);
  const [isVisible, setIsVisible] = React.useState(false);

  const showModal = React.useCallback<ShowModalFn<TVideo, TMoment>>(
    (videoItem, momentItem) => {
      if (!momentItem) {
        setMoment(null);
      } else if ('url' in momentItem) {
        setMoment(momentItem);
      } else {
        setMoment({ ...momentItem, url: getMomentPath(momentItem, origin) });
      }

      if ('url' in videoItem) {
        setVideo(videoItem);
      } else {
        setVideo({ ...videoItem, url: getVideoPath(videoItem, origin) });
      }
      analyticsHandler(ShareEvents.open);
      setIsVisible(true);
    },
    [origin, analyticsHandler],
  );

  const onClose = React.useCallback(() => setIsVisible(false), []);

  return {
    isReady: true,
    isVisible,
    video,
    moment,
    setModalVisibility: setIsVisible,
    showModal,
    onClose,
    analyticsHandler,
  };
}

export const ShareModalContextProvider: React.FC<{
  origin?: string;
  analyticsHandler?: ShareEventsHandler;
}> = ({ children, origin, analyticsHandler = noop }) => {
  if (!origin) {
    throw new Error(MISSING_ORIGIN_ERROR);
  }
  const playerContext = useCreateShareModalContext(origin, analyticsHandler);
  const { isReady, ...props } = playerContext;
  return (
    <ShareModalContext.Provider value={playerContext as any}>
      {children}
      {isReady && <ShareModal {...props} />}
    </ShareModalContext.Provider>
  );
};

const getVideoPath = ({ slug, id }: { slug: string; id: string }, origin: string) =>
  `${origin}/video/${id}/${slug}`;
const getMomentPath = ({ slug, id }: { slug: string; id: string }, origin: string) =>
  `${origin}/moment/${id}/${slug}`;

export const getShareItems = <
  TVideo extends ShareItem = VideoItemVideo,
  TMoment extends ShareItem = VideoItemMoment
>(
  videoItem: TVideo | TMoment,
): [TVideo, TMoment?] => {
  if (videoItem.type === VideoItemType.Video) {
    return [videoItem as TVideo];
  }
  if (!(videoItem as TMoment).video) {
    throw new Error('Property "video: VideoItemVideo" is missing.');
  }
  return [(videoItem as TMoment).video as TVideo, videoItem as TMoment];
};
